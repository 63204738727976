import React from 'react';
import ReactMapboxGl, {Marker, Cluster} from 'react-mapbox-gl';
import * as MapboxGL from 'mapbox-gl';
import MapCompanyMarker from "./MapCompanyMarker";
import MapVehicleMarker from "./MapVehicleMarker";

const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoicGFja2lkIiwiYSI6ImNqemxrazB0ODB3dmszZGxpYXF4YWVpb20ifQ.g5K4LCsadLLVLzdjEbeWHw',
});

const mapProps = {
    center: [-55.388927, -13.569827],
    zoom: [5],
    style: 'mapbox://styles/mapbox/streets-v11',
};

class MapboxContainer extends React.Component {

    constructor(props) {
        super(props);
    };

    clusterMarker = (coordinates, pointCount) => (
        <Marker key={coordinates} coordinates={coordinates}>
            <div className={'cluster-map-item'}>{pointCount}</div>
        </Marker>
    );

    render() {

        let mapBounds = [];
        let companies = [];
        this.props.companies.map(company => {
            if (this.props.showCompanies && company.latitude && company.longitude) {
                mapBounds.push([company.longitude, company.latitude]);
                companies.push(
                    <Marker key={company.id} coordinates={[company.longitude, company.latitude]} anchor={'center'}>
                        <MapCompanyMarker company={company}/>
                    </Marker>)
            }
        });

        let markers = [];
        this.props.vehicles.map(vehicle => {
            if (this.props.showOff || vehicle.last_info.connected) {
							if(vehicle.last_info.longitude != 0 && vehicle.last_info.latitude != 0){
								mapBounds.push([vehicle.last_info.longitude, vehicle.last_info.latitude]);
                markers.push(
                    <Marker key={"" + vehicle.id + vehicle.last_info.latitude + vehicle.last_info.longitude + ""}
                            coordinates={[vehicle.last_info.longitude, vehicle.last_info.latitude]} anchor={'center'}>
                        <MapVehicleMarker vehicle={vehicle}/>
                    </Marker>)
							}
            }
        });

        var bounds = null;
        if(mapBounds.length > 0) {
            var bounds = mapBounds.reduce(function (bounds, coord) {
                return bounds.extend(coord);
            }, new MapboxGL.LngLatBounds(mapBounds[0], mapBounds[0]));
        }

        return (
            <div style={{height: '98vh', width: '100%'}} className="map-container-map">

                <Map {...mapProps} containerStyle={{
                    height: "98vh",
                    width: "100vw"
                }} fitBounds={bounds}
                >
                    {companies}

                    <Cluster ClusterMarkerFactory={this.clusterMarker}>
                        {markers}
                    </Cluster>

                </Map>

            </div>
        );
    }
}

export default MapboxContainer
