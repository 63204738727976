import React from 'react';
import Select from 'react-select';
import {debounce} from "lodash";
import Loading from 'react-simple-loading';

class MapFilterContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedCompanies: null,
            showOff: true,
            showCompanies: true
        };

        this.selectedCompaniesHandler = debounce(this.props.selectedCompaniesHandler, 500)
    };

    translateToSelectOption = (companies) => {
        return companies.map(company => {
            return {value: company.id, label: company.name}
        })
    }

    handleChangeSelect = (value) => {
        console.log("Valores do select mudaram", value)
        this.setState({selectedCompanies: value})
    }

    handleChangeShowOff = (event) => {
        this.setState({showOff: event.target.checked})
    }

    handleChangeShowCompanies = (event) => {
        this.setState({showCompanies: event.target.checked})
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.companies !== prevProps.companies) {
            this.setState({selectedCompanies: this.translateToSelectOption(this.props.companies)})
        }
        if (this.state.selectedCompanies !== prevState.selectedCompanies) {
            this.selectedCompaniesHandler(this.state.selectedCompanies)
        }

        if (this.state.showOff !== prevState.showOff) {
            this.props.showOffHandler(this.state.showOff)
        }

        if (this.state.showCompanies !== prevState.showCompanies) {
            this.props.showCompaniesHandler(this.state.showCompanies)
        }
    }

    render() {

        return (
            <div className="map-filter-wrapper">
                {!this.props.isLoadingCompanies ? (
                    <div className="row map-filter-container">
                        <div className="col s12 m3 right show-options-wrapper">
                            <div className="show-off-container">
                                <div className="pretty p-switch">
                                    <input type="checkbox" id="show-off-signal" name="show-off-signal"
                                           onChange={this.handleChangeShowOff} checked={this.state.showOff}/>
                                    <div className="state p-success">
                                        <label htmlFor="show-off-signal">{this.props.translate.show_no_signal}</label>
                                    </div>
                                </div>
                            </div>

                            <div className="show-companies-container">
                                <div className="pretty p-switch">
                                    <input type="checkbox" id="show-companies-signal" name="show-companies-signal"
                                           onChange={this.handleChangeShowCompanies} checked={this.state.showCompanies}/>
                                    <div className="state p-success">
                                        <label htmlFor="show-companies-signal">{this.props.translate.show_companies}</label>
                                    </div>
                                </div>
                            </div>

                            {this.props.isLoadingVehicles ? (
                                <div className="is-loading-vehicles-container" style={{float: 'left', width: '100%', marginTop: '8px'}}>
                                    <div style={{width: '28px', height: '28px', float: 'left'}}><Loading size={'28px'} color={'#73879c'} /></div>
                                    <div style={{float: 'left', marginLeft: '8px'}}>Carregando veículos...</div>
                                </div>
                            ) : null}

                        </div>

                        <Select
                            className="col s12 m9 left"
                            isMulti={true}
                            onChange={this.handleChangeSelect}
                            value={this.state.selectedCompanies}
                            options={this.translateToSelectOption(this.props.companies)}
                            placeholder="Selecione as empresas que deseja exibir"
                            noOptionsMessage={() => "Nenhuma empresa com transportes disponível"}
                        />
                    </div>
                ) : (<div>Carregando dados iniciais...</div>)}

            </div>
        );
    }
}

export default MapFilterContainer
