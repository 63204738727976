import React from "react";
import SelectCompany from '../shared/SelectCompany';
import SelectSensor from '../shared/SelectSensor';
import Flatpickr from 'react-flatpickr'
import {Portuguese} from "flatpickr/dist/l10n/pt.js"

class AnaliseTemporalFilter extends React.Component {

    constructor(props) {
        var d = new Date();
        d.setDate(d.getDate()-7);
        
        super(props);
        this.state = {
            selectedCompanyId: null,
            selectedSensorId: null,
            dateInicio: null,
            dateFim: null,
            minDate: d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear(),
            maxDate: "today",
            maxTemperature: null,
            minTemperature: null,
            dateFimDisabled: true
        };
    };

    onSelectedCompany = (company) => {
        this.setState({selectedCompanyId: company.id})
    };

    onSelectedSensor = (sensor) => {
        this.setState({
            selectedSensorId: sensor.id,
            maxTemperature: parseFloat(sensor.temperature_max),
            minTemperature: parseFloat(sensor.temperature_min)
        })
    };

    onDataInicioChange = (date) => {
        let newMinDate = moment(date[0]).format('DD/MM/YYYY');
        let newMaxDate = moment(date[0]).add(7, 'days').format('DD/MM/YYYY');

        this.setState({
            dateFimDisabled: false,
            dateInicio: date[0],
            minDate: newMinDate,
            maxDate: newMaxDate
        })
    };

    onDataFimChange = (date) => {
        this.setState({dateFim: date[0]})
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedCompanyId != this.state.selectedCompanyId ||
            prevState.selectedSensorId != this.state.selectedSensorId ||
            prevState.dateInicio != this.state.dateInicio ||
            prevState.dateFim != this.state.dateFim) {
            this.props.onChangeFilterParams(this.state)
        }
    }

    render() {

        return (
            <div className="analise-temporal-filter">
                <div className="row">
                    <div className="inputs-analise-temporal">

                        <div className="col s12 m3">
                            <SelectCompany onSelectedCompany={this.onSelectedCompany}
                                           token={this.props.token}
                                           userId={this.props.userId}/>
                        </div>

                        <div className="col s12 m3">
                            <SelectSensor onSelectedSensor={this.onSelectedSensor}
                                          companyId={this.state.selectedCompanyId}
                                          token={this.props.token}
                                          userId={this.props.userId}/>
                        </div>

                        <div className="col s12 m3">
                            <div className="custom-flatpickr-wrapper">
                                <div className="flatpickr-label">{this.props.translate.initial_date}</div>
                                <Flatpickr className="flatpickr flatpickr-input active custom-flatpickr"
                                           onChange={this.onDataInicioChange}
                                           options={{
                                               locale: Portuguese,
                                               dateFormat: "d/m/Y",
                                               maxDate: this.state.minDate
                                           }}/>
                            </div>
                        </div>

                        <div className="col s12 m3">
                            <div className="custom-flatpickr-wrapper">
                                <div className="flatpickr-label">{this.props.translate.final_date}</div>
                                <Flatpickr className="flatpickr flatpickr-input active custom-flatpickr"
                                           onChange={this.onDataFimChange}
                                           options={{
                                               locale: Portuguese,
                                               dateFormat: "d/m/Y",
                                               minDate: this.state.minDate,
                                               maxDate: this.state.maxDate
                                           }}
                                           disabled={this.state.dateFimDisabled} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}

export default AnaliseTemporalFilter

