import React from "react"
import TimeDisplay from "../shared/TimeDisplay"
import MoneyDisplay from "../shared/MoneyDisplay"

class AmbienteListItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_showing_sensors: false
        };
    };

    handleToggleButton = () => {
        this.setState({
            is_showing_sensors: !this.state.is_showing_sensors
        })
    }

    render() {

        let produtos = (<p>{this.props.translate.no_products_registered_this_environment}</p>);
        if (this.props.ambiente.produtos.length) {

            produtos = [];
            produtos.push(<p key={0} className="products-title">Produtos neste ambiente:</p>);

            produtos.push(this.props.ambiente.produtos.map(produto => {
                return (<p key={produto.id}>{produto.name}</p>)
            }))
        }

        let sensores = (<p>Nenhum sensor cadastrado para este ambiente</p>);
        if (this.props.ambiente.sensores.length) {

            sensores = [];
            sensores.push(<p key={0} className="sensores-title">{this.props.translate.sensors_in_this_environment}</p>);

            sensores.push(this.props.ambiente.sensores.map(sensor => {
                return (<p key={sensor.id}><a href={"/medicoes?id=" + sensor.serial}>{sensor.label}</a></p>)
            }))
        }

        let energyData = null;
        if (this.props.ambiente.is_energia) {

            let motorWord = this.props.ambiente.quantidade_motor > 1 ? "motores" : "motor"

            energyData = (
                <p className="left">{this.props.ambiente.quantidade_motor} {motorWord} de {parseInt(this.props.ambiente.consumo_motores)} watts</p>)
        } else {
            energyData = (<p className="left">Motores a diesel</p>)
        }

        let wordForButton = this.state.is_showing_sensors ? "Ocultar" : "Mostrar";

        return (

            <div className={"ambiente-item col s12 " + (this.props.ambiente.total_time > 0 ? "bad" : "good")}>
                <div>
                    <div className="left col s8">
                        <div className="ambiente-name">
                            {this.props.ambiente.name}
                        </div>
                        <div className="ambiente-type">
                            {this.props.ambiente.tipo}
                        </div>
                        <div className="ambiente-products">
                            {produtos}
                        </div>
                        <div className="ambiente-more-data">
                            {energyData}
                        </div>
                    </div>

                    <div className="right col s4 right-info">

                        <div>

                            <div>
                                <p className="subtitle-info-ambiente">{this.props.translate.time_below_the_temperature}</p>
                                <p className={"info-ambiente " + (this.props.ambiente.total_time > 0 ? "bad" : "good")}><TimeDisplay value={this.props.ambiente.total_time}/></p>
                            </div>

                            <div>
                                <p className="subtitle-info-ambiente">{this.props.translate.wasted_cash_value}</p>
                                <p className={"info-ambiente " + (this.props.ambiente.total_cost > 0 ? "bad" : "good")}><MoneyDisplay value={this.props.ambiente.total_cost}/></p>
                            </div>

                            <button className="btn btn-ver-sensores right" data-toggle="collapse"
                                    data-target={"#ambiente_sensors_" + this.props.ambiente.id}
                                    onClick={this.handleToggleButton}>{wordForButton} {this.props.translate.sensors_text}</button>
                        </div>
                    </div>
                    <div id={"ambiente_sensors_" + this.props.ambiente.id}
                         className={"left ambiente-sensor-list col s12 " + (this.state.is_showing_sensors ? "" : "hidden")} >
                        
                        {sensores}

                    </div>
                </div>
            </div>

        );
    }
}

export default AmbienteListItem
