import React from 'react';

class MapVehicleClusterMarker extends React.Component {

    render() {

        return (
            <div className='vehicle-cluster-marker'>
                {this.props.counter}
            </div>
        );
    }
}

export default MapVehicleClusterMarker
