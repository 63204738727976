import React from "react";

class RelatorioCadeiaOverviewItem extends React.Component {

    render() {

        return (
            <div className="col s12 m6 l4">
                <div className="relatorio-cadeia-overview-item">
                    <div className="relatorio-cadeia-overview-title">{this.props.overview_info.label}</div>
                    <div className="container item-overview-wrapper">
                        <div className="item-overview-container">
                            <div className="item-overview-value below">{this.props.overview_info.temperature_min}</div>
                            <div className="item-overview-title">{this.props.translate.temperature}<br/>{this.props.translate.minimum}</div>
                        </div>
                        <div className="item-overview-container">
                            <div
                                className="item-overview-value average">{this.props.overview_info.average_temperature}</div>
                            <div className="item-overview-title">{this.props.translate.temperature}<br/>{this.props.translate.average}</div>
                        </div>
                        <div className="item-overview-container">
                            <div className="item-overview-value above">{this.props.overview_info.temperature_max}</div>
                            <div className="item-overview-title">{this.props.translate.temperature}<br/>{this.props.translate.maximum}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RelatorioCadeiaOverviewItem
