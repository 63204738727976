import React from "react"
import LoadingOverlay from "react-loading-overlay";
import SmeMessageItem from '../sme_messages/SmeMessageItem';
import Input from '../Input';
import Select from '../Select';
import TextArea from "../TextArea";
import MultipleSelect from "../MultipleSelect";
import axios from 'axios'
import expoImg from '../../../assets/images/expo.png'

class SmeNewMessage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            statusRequest: '',
            companies: [],
            users: [],
						loading: true,
				}
				
    }

    initializeModal = (id) => {
        var elems = document.getElementById(id);
        var instances = M.Modal.init(elems);   
    }

    gettingUsersAndCompanies = () => {
        var url_to_request = '/api/v2/sme_new_message_select';
        return axios.get(url_to_request,
                {
                    headers: {
                        "Authorization": this.props.token
                    }
                })
                .then(response => {
                    if(response.status == 200){
                        var companiesOptions = response.data.companies.map((item, index) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))   

                        var usersOptions = response.data.users.map((item, index) => (
                            <option key={item.id} value={item.id}>{item.name} - {item.company_name}</option>
                        ))   

                        var companies = <MultipleSelect
                                            id={'companies-select'}
                                            alt={'Seletor para filtro de empresas'}
                                            classes={'input-field'}
                                            options={companiesOptions}
                                            label={'Selecione as empresas'}
                                        />

                        var users = <MultipleSelect
                                        id={'users-select'}
                                        alt={'Seletor para filtro de usuários'}
                                        classes={'input-field'}
                                        options={usersOptions}
                                        label={'Selecione os usuários'}
                                    />
                        
                        this.setState({companies: companies}); 
                        this.setState({users: users});
                        this.setState({statusRequest: true});
                        this.setState({loading: false});
                    }
                })
                .catch(error => {
                    return Promise.reject(error);
                });
    }

    getInfoToSend(){
			var inputTitleValue = document.getElementById('messageTitle').value
			var textAreaValue = document.getElementById('messageBody').value
			var userDestination = []
			var companyDestination = []

			document.querySelectorAll( "select#users-select option:checked").forEach(element => {userDestination.push(element.value)});
			document.querySelectorAll( "select#companies-select option:checked").forEach(element => {companyDestination.push(element.value)});

			var typeSelect = document.getElementById('typeSelect').value

			if(typeSelect == 'E-mail'){
				typeSelect = 1
			}else if(typeSelect == 'App'){
				typeSelect = 2
			}else if(typeSelect == 'Todos'){
				typeSelect = 3
			}

			var infoToSend = {
				'title': inputTitleValue,
				'body': textAreaValue,
				'users': userDestination.toString(),
				'companies': companyDestination.toString(),
				'type_message': typeSelect
			}

			var url_to_request = '/api/v2/sme_messages';
			return axios.post(url_to_request,
							{
								msg_form: infoToSend
							},
							{
									headers: {
										"Authorization": this.props.token
									}
							})
							.then(response => {
								if(response.status == 200){
									M.toast({html: 'Mensagem enviada!'})
								}else{
									M.toast({html: 'Erro ao enviar mensagem!'})
								}
							})
							.catch(error => {
								return Promise.reject(error);
							});

		}
		
		componentDidMount(){
			this.gettingUsersAndCompanies()

			var id = this.props.id
			this.initializeModal(id)

			document.getElementById('messageTitle').onkeyup = () => {
				var qnt = document.getElementById('messageTitle').value.length
				document.getElementById('sme-title__limit').innerHTML = 'Limite de caracteres: ' + qnt + '/65'
				document.getElementById('sme-example__title').innerHTML = document.getElementById('messageTitle').value
			};

			document.getElementById('messageBody').onkeyup = () => {
				var qnt = document.getElementById('messageBody').value.length
				document.getElementById('sme-body__limit').innerHTML = 'Limite de caracteres: ' + qnt + '/240'
				document.getElementById('sme-example__body').innerHTML = document.getElementById('messageBody').value
			};

		}

    render() {
        var options = ['Todos', 'E-mail', 'App']
        return(
					<div className={this.props.classes} id={this.props.id}>
						<LoadingOverlay
										active={this.state.loading}
										spinner
										color="#40BDA7"
										text='Carregando dados'/>
						<div className={this.state.loading == true ? "modal-content loading" :"modal-content"}>
							<div className="sme-title">
								<Input
										type={'text'}
										placeholder={'Escreva o título da mensagem'}
										id={'messageTitle'}
										alt={'Campo para escrever o título da mensagem'}
										classes={'input-field'}
										label={'Título da mensagem'}
								/>
								<small id="sme-title__limit"></small>
							</div>
						<div>

						<div className="sme-body"> 
							<TextArea
								placeholder={'Escreva o título da mensagem'}
								id={'messageBody'}
								alt={'Campo para escrever o título da mensagem'}
								classes={'input-field'}
								label={'Conteúdo da mensagem'}
							/>
							<small id="sme-body__limit"></small>
						</div>

						<div className="sme-example">
							<img src={expoImg}></img>
							<div id="sme-example__title"></div>
							<div id="sme-example__body"></div>
						</div>

						{ this.state.statusRequest = 200 ? this.state.companies : null }
						{ this.state.statusRequest = 200 ? this.state.users : null }

						<Select
								id={'typeSelect'}
								alt={'Seletor para tipo de mensagem'}
								classes={'input-field'}
								options={options}
						/> 

						<a className="btn left" id="new-message-btn" onClick={() => this.getInfoToSend()}>Enviar mensagem</a>
							
						</div>
					</div>
					<div className="modal-footer">
					</div>
				</div>
        );
    }
}

export default SmeNewMessage