import React from 'react'
import MapboxContainer from './MapboxContainer'
import MapFilterContainer from './MapFilterContainer'
import * as Requests from './MapRequests'

class MapPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoadingCompanies: true,
            companies: [],
            isLoadingVehicles: true,
            vehicles: [],
            showOff: true,
            showCompanies: true
        };
    };

    componentDidMount() {
        Requests.fetchCompanies(this.props.token)
            .then(companies => {
                this.setState({companies: companies.data, isLoadingCompanies: false})
            })
            .catch(e => {
                console.log(e)
            })
    }

    selectedCompaniesHandler = (selectedCompanies) => {

        this.setState({isLoadingVehicles: true})

        Requests.fetchLastPositions(selectedCompanies.map(company => {
            return company.value
        }), this.props.token)
            .then(lastPositions => {
                this.setState({vehicles: lastPositions.data, isLoadingVehicles: false})
            })
            .catch(e => {
                console.log(e)
            })
    }

    showOffHandler = (showOff) => {
        this.setState({showOff: showOff})
    }

    showCompaniesHandler = (showCompanies) => {
        this.setState({showCompanies: showCompanies})
    }

    render() {

        return (
            <div className="map-container-page">
                <MapFilterContainer companies={this.state.companies}
                                    isLoadingCompanies={this.state.isLoadingCompanies}
                                    isLoadingVehicles={this.state.isLoadingVehicles}
                                    selectedCompaniesHandler={this.selectedCompaniesHandler}
                                    showOffHandler={this.showOffHandler}
                                    showCompaniesHandler={this.showCompaniesHandler}
                                    translate={this.props.translate}/>
                <MapboxContainer companies={this.state.companies}
                                    vehicles={this.state.vehicles}
                                    isLoadingVehicles={this.state.isLoadingVehicles}
                                    showOff={this.state.showOff}
                                    showCompanies={this.state.showCompanies}
                                    translate={this.props.translate}/>
            </div>
        );
    }
}

export default MapPage
