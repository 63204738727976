import React from "react"
import { debounce } from "lodash"

class DateFilter extends React.Component {

    constructor(props) {
        super(props);
        let now = new Date();
        this.state = {
            mes: now.getMonth() + 1,
            ano: now.getYear() + 1900
        };
        this.onChangeDateHandler = debounce(this.props.onChangeDateHandler, 350)
    };

    months = {
        1: "Janeiro", 2: "Fevereiro", 3: "Março",
        4: "Abril", 5: "Maio", 6: "Junho",
        7: "Julho", 8: "Agosto", 9: "Setembro",
        10: "Outubro", 11: "Novembro", 12: "Dezembro",
    };

    atLeastTwoDigits(number) {
        if(number < 10) {
            return '0' + number
        }
        return number
    }

    monthIncrement(increment) {

        let currentMonth = this.state.mes;
        let currentYear = this.state.ano;
        let newMonth = currentMonth + increment;
        let newYear = currentYear;

        let today = new Date();

        if ((newYear+ '/' + this.atLeastTwoDigits(newMonth)) > (today.getFullYear()+ '/' + this.atLeastTwoDigits(today.getMonth()+1))) {
            return false;
        }

        if (newMonth == 0) {
            newMonth = 12;
            newYear = currentYear - 1;
        } else if (newMonth == 13) {
            newMonth = 1;
            newYear = currentYear + 1;
        }

        this.setState({
            mes: newMonth,
            ano: newYear
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.onChangeDateHandler && (this.state.mes !== prevState.mes)) {
            this.onChangeDateHandler(this.state.mes, this.state.ano);
        }
    }

    render() {

        let text = this.months[this.state.mes] + " de " + this.state.ano;

        return (
            <div className="date-filter-container">
                <div className="full-width">
                    <div className="date-filter-text">
                        <button className="btn-date-filter btn-date-filter-previous" onClick={() => this.monthIncrement(-1)}>
                            <i className="fa fa-chevron-left"></i>
                        </button>
                        {text}
                        <button className="btn-date-filter btn-date-filter-next" onClick={() => this.monthIncrement(1)}>
                            <i className="fa fa-chevron-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default DateFilter
