import React from "react"
import * as Requests from './RelatorioCadeiaRequests'

import Flatpickr from 'react-flatpickr'
import {Portuguese} from "flatpickr/dist/l10n/pt.js"

class EtapaCadeia extends React.Component {

    constructor(props) {
        super(props);
    };

    handleSelectCompanyChange = (event) => {
        const target = event.target;

        let selectedCompany = this.props.companies.find(company => {
            return company.id == target.value
        });

        this.props.changeDataHandler({
            selected_company_id: parseInt(target.value),
            selected_sensor_id: parseInt(selectedCompany.sensors[0].id),
            date_inicio: null,
            date_final: null
        }, this.props.index);
    };

    handleSelectSensorGroupChange = (event) => {
        const target = event.target;

        this.props.changeDataHandler({
            selected_sensor_id: parseInt(target.value)
        }, this.props.index);
    };

    handleDataInicioChange = (date) => {
        this.props.changeDataHandler({
            date_inicio: date[0]
        }, this.props.index);
    };

    handleDataFinalChange = (date) => {
        this.props.changeDataHandler({
            date_final: date[0]
        }, this.props.index);
    };

    checkEficienciaData = async () => {
        if (this.props.selected_company_id &&
            this.props.selected_sensor_id &&
            this.props.date_inicio &&
            this.props.date_final) {

            // Busca os dados da barra de eficiência
            await Requests.fetchEfficiencyData(
                this.props.selected_sensor_id,
                this.props.date_inicio,
                this.props.date_final,
                this.props.token,
                'temperature',
                'min')
                .then(result => {
                    this.props.changeDataHandler({bar_info: result.data}, this.props.index);
                })
                .catch(err => {
                    console.log(err);
                });

            // Seta o loading do chart e busca as informações para o chart
            this.props.changeLoadingChartDataStatusHandler(true);
            await Requests.fetchChartData(
                this.props.selected_sensor_id,
                this.props.date_inicio,
                this.props.date_final,
                this.props.token,
                'temperature',
                'min')
                .then(result => {
                    this.props.changeDataHandler({chart_data: result.data}, this.props.index);
                    this.props.changeLoadingChartDataStatusHandler(false);
                })
                .catch(err => {
                    console.log(err);
                    this.props.changeLoadingChartDataStatusHandler(false);
                });

            

            // Seta o loading do chart e busca as informações para o chart
            this.props.changeLoadingChartDataStatusHandler(true);
            await Requests.fetchChartData(
                this.props.selected_sensor_id,
                this.props.date_inicio,
                this.props.date_final,
                this.props.token,
                'humidity',
                'min')
                .then(result => {
                    this.props.changeDataHandler({chart_data_humidity: result.data}, this.props.index);
                    this.props.changeLoadingChartDataStatusHandler(false);
                })
                .catch(err => {
                    console.log(err);
                    this.props.changeLoadingChartDataStatusHandler(false);
                });

            // Busca os dados de overview do sensor
            await Requests.fetchOverviewData(
                this.props.selected_sensor_id,
                this.props.date_inicio,
                this.props.date_final,
                this.props.token,
                'temperature',
                'min')
                .then(result => {
                    this.props.changeDataHandler({overview_info: result.data}, this.props.index);
                })
                .catch(err => {
                    console.log(err);
                })

            // Busca os dados para montar a tabela
            await Requests.fetchTableData(
                this.props.selected_sensor_id,
                this.props.date_inicio,
                this.props.date_final,
                this.props.token,
                'temperature',
                'min')
                .then(result => {
                    this.props.changeDataHandler({table_info: result.data}, this.props.index);
                })
                .catch(err => {
                    console.log(err);
                });

            // Busca os dados para montar a tabela
            await Requests.fetchTableData(
                this.props.selected_sensor_id,
                this.props.date_inicio,
                this.props.date_final,
                this.props.token,
                'humidity',
                'min')
                .then(result => {
                    this.props.changeDataHandler({table_info_humidity: result.data}, this.props.index);
                })
                .catch(err => {
                    console.log(err);
                });

            // Busca os dados da barra de eficiência umidade
            await Requests.fetchEfficiencyData(
                this.props.selected_sensor_id,
                this.props.date_inicio,
                this.props.date_final,
                this.props.token,
                'humidity',
                'min')
                .then(result => {
                    this.props.changeDataHandler({bar_info_humidity: result.data}, this.props.index);
                })
                .catch(err => {
                    console.log(err);
                });

        }
    };

    componentDidMount() {
        if (this.props.selected_company_id &&
            this.props.selected_sensor_id &&
            this.props.date_inicio &&
            this.props.date_final) {

            this.checkEficienciaData();

        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selected_company_id != prevProps.selected_company_id ||
            this.props.selected_sensor_id != prevProps.selected_sensor_id ||
            this.props.date_inicio != prevProps.date_inicio ||
            this.props.date_final != prevProps.date_final) {

            this.checkEficienciaData();

        }
    }

    render() {
        const maxEtapas = 12;

        let optionsCompanies = this.props.companies.map(company => {
            return (<option key={company.id}
                            value={company.id}>{company.name}</option>)
        });

        let optionsSensors = [];
        let selectedCompany = this.props.companies.find(company => {
            return company.id == this.props.selected_company_id;
        });

        if (selectedCompany && selectedCompany.sensors) {

            let data = {sensors: [], groups: []};

            selectedCompany.sensors.forEach(sensor => {
                if (!sensor.group) {
                    data.sensors.push(sensor)
                } else {
                    if (!data.groups[sensor.group.id]) {
                        data.groups[sensor.group.id] = []
                    }
                    data.groups[sensor.group.id].push(sensor)
                }
            });

            let subOptions = data.sensors.map(sensor => {
                return (<option key={sensor.id}
                                value={sensor.id}>{sensor.label}</option>)
            });
            if (data.sensors.length) {
                optionsSensors.push(<optgroup key="sensor_options" label="Sensores">{subOptions}</optgroup>)
            }

            data.groups.forEach(group => {
                let subOptionsGroup = group.map(sensor => {
                    return (<option key={sensor.id}
                                    value={sensor.id}>{sensor.label}</option>)
                });
                optionsSensors.push(<optgroup key={group[0].group.id}
                                              label={group[0].group.name}>{subOptionsGroup}</optgroup>)
            })

        }

        let eficienciaArea = this.props.bar_info && this.props.overview_info ? (<div className="eficiencia-area">

            <div className="col s12 m6 relatorio-cadeia-overview-container">
                <div className="relatorio-cadeia-overview-item">
                    <div className="container item-overview-wrapper">
                        <div className="item-overview-container">
                            <div className="item-overview-value below">{this.props.overview_info.min_temperature_etapa} ºC</div>
                            <div className="item-overview-title">{this.props.translate.temperature}<br/>{this.props.translate.minimum}</div>
                        </div>
                        <div className="item-overview-container">
                            <div
                                className="item-overview-value average">{this.props.overview_info.average_temperature} ºC</div>
                            <div className="item-overview-title">{this.props.translate.temperature}<br/>{this.props.translate.average}</div>
                        </div>
                        <div className="item-overview-container">
                            <div className="item-overview-value above">{this.props.overview_info.max_temperature_etapa} ºC</div>
                            <div className="item-overview-title">{this.props.translate.temperature}<br/>{this.props.translate.maximum}</div>
                        </div>
                        <div className="item-overview-container">
                            <div className="item-overview-value below">{this.props.overview_info.min_humidity_etapa}%</div>
                            <div className="item-overview-title">{this.props.translate.humidity}<br/>{this.props.translate.minimum}</div>
                        </div>
                        <div className="item-overview-container">
                            <div
                                className="item-overview-value average">{this.props.overview_info.average_humidity}%</div>
                            <div className="item-overview-title">{this.props.translate.humidity}<br/>{this.props.translate.average}</div>
                        </div>
                        <div className="item-overview-container">
                            <div className="item-overview-value above">{this.props.overview_info.max_humidity_etapa}%</div>
                            <div className="item-overview-title">{this.props.translate.humidity}<br/>{this.props.translate.maximum}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col s12 m6">

                {this.props.bar_info.sector_size > 0 ? (
                    <div className="eficiencia-container">

                        <div className="eficiencia-bar">
                            <div className="normal-bar" style={{width: '' + this.props.bar_info.normal + '%'}}></div>
                            <div className="above-bar" style={{width: '' + this.props.bar_info.above + '%'}}></div>
                            <div className="below-bar" style={{width: '' + this.props.bar_info.below + '%'}}></div>
                        </div>

                        <div className="subitem-eficiencia-faixa-wrapper">

                            {this.props.bar_info.normal > 0 ? (
                                <div className="subitem-eficiencia-faixa normal"
                                     style={{width: '' + this.props.bar_info.sector_size + '%'}}>

                                    <div className="full-width">
                                        <h2>{this.props.bar_info.normal}%</h2>
                                        <p>{this.props.translate.within_range}</p>
                                    </div>
                                </div>
                            ) : null}

                            {this.props.bar_info.above > 0 ? (
                                <div className="subitem-eficiencia-faixa above"
                                     style={{width: '' + this.props.bar_info.sector_size + '%'}}>

                                    <div className="full-width">
                                        <h2>{this.props.bar_info.above}%</h2>
                                        <p>{this.props.translate.over_range}</p>
                                    </div>
                                </div>
                            ) : null}

                            {this.props.bar_info.below > 0 ? (
                                <div className="subitem-eficiencia-faixa below"
                                     style={{width: '' + this.props.bar_info.sector_size + '%'}}>

                                    <div className="full-width">
                                        <h2>{this.props.bar_info.below}%</h2>
                                        <p>{this.props.translate.bellow_range}</p>
                                    </div>
                                </div>
                            ) : null}

                        </div>

                    </div>
                ) : (<div className="eficiencia-container text-center">{this.props.translate.no_data_for_this_date}</div>)}

                {this.props.bar_info_humidity?.sector_size > 0 ? (
                    <div className="eficiencia-container">

                        <div className="eficiencia-bar">
                            <div className="normal-bar" style={{width: '' + this.props.bar_info_humidity.normal + '%'}}></div>
                            <div className="above-bar" style={{width: '' + this.props.bar_info_humidity.above + '%'}}></div>
                            <div className="below-bar" style={{width: '' + this.props.bar_info_humidity.below + '%'}}></div>
                        </div>

                        <div className="subitem-eficiencia-faixa-wrapper">

                            {this.props.bar_info_humidity.normal > 0 ? (
                                <div className="subitem-eficiencia-faixa normal"
                                     style={{width: '' + this.props.bar_info_humidity.sector_size + '%'}}>

                                    <div className="full-width">
                                        <h2>{this.props.bar_info_humidity.normal}%</h2>
                                        <p>{this.props.translate.within_range}</p>
                                    </div>
                                </div>
                            ) : null}

                            {this.props.bar_info_humidity.above > 0 ? (
                                <div className="subitem-eficiencia-faixa above"
                                     style={{width: '' + this.props.bar_info_humidity.sector_size + '%'}}>

                                    <div className="full-width">
                                        <h2>{this.props.bar_info_humidity.above}%</h2>
                                        <p>{this.props.translate.over_range}</p>
                                    </div>
                                </div>
                            ) : null}

                            {this.props.bar_info_humidity.below > 0 ? (
                                <div className="subitem-eficiencia-faixa below"
                                     style={{width: '' + this.props.bar_info_humidity.sector_size + '%'}}>

                                    <div className="full-width">
                                        <h2>{this.props.bar_info_humidity.below}%</h2>
                                        <p>{this.props.translate.bellow_range}</p>
                                    </div>
                                </div>
                            ) : null}

                        </div>

                    </div>
                ) : (<div className="eficiencia-container text-center">{this.props.translate.no_data_for_this_date}</div>)}


            </div>


        </div>) : null;

        let tableArea = null;
        if (this.props.table_info) {
            console.info(this.props.table_info_humidity);

            let rowsTable = this.props.table_info.map((rowData, index)=> {

                const rowHumidity = this.props.table_info_humidity ? this.props.table_info_humidity[index] : null;
                let rowLines = rowData.dados.map((lineData, index) => {
                    const humidity = rowHumidity ? rowHumidity.dados[index] : null;

                    return <td key={`temp-${index}`} className={lineData.class}>{lineData.value}</td>
                });

                return (<tr key={`temp-${index}`}>
                    <td>{rowData.dia}</td>
                    {rowLines}
                </tr>)
            });

            let rowsTableHumidity = this.props.table_info_humidity?.map((rowData, index)=> {
                let rowLines = rowData.dados.map((lineData, index) => {
                    return <td key={`humi-${index}`} className={lineData.class}>{lineData.value}</td>
                });

                return (<tr key={`humi-${index}`}>
                    <td>{rowData.dia}</td>
                    {rowLines}
                </tr>)
            });

            tableArea = (<div className="table-area">
                <div className="col s12">
                    <table>
                        <thead>
                        <tr>
                            <th>{this.props.translate.day}</th>
                            <th>0H</th>
                            <th>1H</th>
                            <th>2H</th>
                            <th>3H</th>
                            <th>4H</th>
                            <th>5H</th>
                            <th>6H</th>
                            <th>7H</th>
                            <th>8H</th>
                            <th>9H</th>
                            <th>10H</th>
                            <th>11H</th>
                            <th>12H</th>
                            <th>13H</th>
                            <th>14H</th>
                            <th>15H</th>
                            <th>16H</th>
                            <th>17H</th>
                            <th>18H</th>
                            <th>19H</th>
                            <th>20H</th>
                            <th>21H</th>
                            <th>22H</th>
                            <th>23H</th>
                        </tr>
                        </thead>
                        <tbody>

                        {rowsTable}

                        <tr>
                            <th colSpan="25">{this.props.translate.humidity}</th>
                        </tr>

                        {rowsTableHumidity}

                        </tbody>
                    </table>
                </div>
            </div>);
        }

        return (
            <div className="etapa-cadeia">
                <div className="number-etapa-wrapper">
                    <div className="number-etapa">{this.props.position}</div>
                    <div className="number-divisor"></div>
                </div>
                <div className="row">
                    <div className="inputs-etapa">

                        <div className="col s12 m3">
                            <div className="custom-input-wrapper">
                                <div className="custom-input-label">{this.props.translate.company}</div>
                                <select className="custom-input"
                                        name="selected_company_id"
                                        onChange={this.handleSelectCompanyChange}
                                        value={this.props.selected_company_id}>
                                    {optionsCompanies}
                                </select>
                            </div>
                        </div>

                        <div className="col s12 m3">
                            <div className="custom-input-wrapper">
                                <div className="custom-input-label">{this.props.translate.sensor_text}/{this.props.translate.group_text}</div>
                                <select className="custom-input"
                                        name="selected_sensor_id"
                                        onChange={this.handleSelectSensorGroupChange}
                                        value={this.props.selected_sensor_id}>
                                    {optionsSensors}
                                </select>
                            </div>
                        </div>

                        <div className="col s12 m3">
                            <div className="custom-flatpickr-wrapper">
                                <div className="flatpickr-label">{this.props.translate.initial_date}</div>
                                <Flatpickr className="flatpickr flatpickr-input active custom-flatpickr"
                                           data-enable-time
                                           value={this.props.date_inicio}
                                           onChange={this.handleDataInicioChange}
                                           options={{
                                               locale: Portuguese,
                                               dateFormat: "d/m/Y H:i",
                                               time_24hr: true,
                                               maxDate: new Date().fp_incr(1)                                        
                                           }}/>
                            </div>
                        </div>

                        <div className="col s12 m3">
                            <div className="custom-flatpickr-wrapper">
                                <div className="flatpickr-label">{this.props.translate.final_date}</div>
                                <Flatpickr className="flatpickr flatpickr-input active custom-flatpickr"
                                           data-enable-time
                                           value={this.props.date_final}
                                           onChange={this.handleDataFinalChange}
                                           options={{
                                               locale: Portuguese,
                                               dateFormat: "d/m/Y H:i",
                                               time_24hr: true,
                                               maxDate: new Date().fp_incr(1)                                           
                                           }}/>
                            </div>
                        </div>

                    </div>

                    {eficienciaArea}

                    {tableArea}

                    <div className="button-area">
                        <div className="col s12">
                            <div
                                className={"container-operation-etapa " + (this.props.isLast && this.props.position < maxEtapas ? "last-etapa" : "")}>
                                <div className="button-operation-etapa-wrapper">
                                    <button className="button-operation-etapa"
                                            onClick={() => {
                                                this.props.isLast && this.props.position < maxEtapas ? this.props.addEtapaHandler() : this.props.removeEtapaHandler(this.props.index)
                                            }}>
                                        {this.props.isLast && this.props.position < maxEtapas ?
                                            `+ ${this.props.translate.add_step}` : `- ${this.props.translate.remove_step}`}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default EtapaCadeia
