import React from "react"
import axios from "axios";
import DesempenhoFixosDados from "./DesempenhoFixosDados";
import DesempenhoFixosChart from "./DesempenhoFixosChart";

const CancelToken = axios.CancelToken;
var cancel;

export function fetchData(company, year, month, token) {
    cancel && cancel()

    return axios.get('/api/v2/performance/fixos/' + company + '/' + year + '/' + month,
        {
            headers: {
                "Authorization": token
            },
            cancelToken: new CancelToken(
                function executor(c) {
                    cancel = c;
                }
            )
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

class DesempenhoFixosContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            data: null
        };

    };

    requestData = () => {

        this.setState({
            isLoading: true,
            data: null
        });

        fetchData(this.props.company, this.props.year, this.props.month, this.props.token)
            .then(response => {
                this.setState({
                    isLoading: false,
                    data: response.data
                });

                console.log(response.data);
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.log(error);
                    this.setState({isLoading: false});
                }
            });

    }

    componentDidMount() {
        this.requestData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.month !== prevProps.month) {
            this.requestData();
        }
    }

    render() {

        return (
            <div className="right-align">

                <div className="row">
                    <h2 className="page-title-custom page-title-custom">Sensores fixos</h2>
                </div>

                <div className="row">

                    <DesempenhoFixosDados isLoading={this.state.isLoading} data={this.state.data} />

                    <DesempenhoFixosChart isLoading={this.state.isLoading} data={this.state.data} />

                </div>

            </div>
        );
    }
}

export default DesempenhoFixosContainer
