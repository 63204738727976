import React from "react"
import chartjs from 'chart.js';
import LoadingOverlay from "react-loading-overlay";

class ChartToInsight extends React.Component {
    constructor(props){
        super(props)
    }

    componentDidMount(){
      var outOfRange = this.props.data.out_of_range;

      delete outOfRange.id
      var datasets = [];
      var label = '', indexOutOfRange = '', backgroundColor = '';
      var daysOfWeek = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];

      var aboveValues = [], belowValues = [];

      for (var prop in outOfRange) {
          if(prop.includes('_above')){
              aboveValues.push(outOfRange[prop]);
          }else{
              belowValues.push(outOfRange[prop]);
          }
      }

      var barChartData = {
        labels: daysOfWeek,
        datasets: [{
          minBarThickness: 20,
          maxBarThickness: 40,
          label: 'Acima da faixa',
          backgroundColor: '#f44236EA',
          borderColor: '#f44236',
          data: aboveValues
        }, {
          minBarThickness: 20,
          maxBarThickness: 40,
          label: 'Abaixo da faixa',
          backgroundColor: '#03a9f5EA',
          borderColor: '#03a9f5',
          data: belowValues
        }]
      };

        var ctx = document.getElementById('chart').getContext('2d');
        var myChart = new Chart(ctx, {
            type: 'bar',
            data: barChartData,
            options: {
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                    xAxes: [{
                      stacked: true,
                      drawBorder: false,
                      gridLines: {
                        drawOnChartArea: false
                      }
                    }],
                    yAxes: [{
                        stacked: true
                    }]
                }
            }
        });    
    }

    render() {
        return(
            <div className={"chart-container"}>
                <canvas id="chart" width={100} height={100}></canvas>
            </div>
        );
    }
}

export default ChartToInsight  