import React from "react"

class Select extends React.Component {
    constructor(props){
        super(props)

        this.initializeSelect = this.initializeSelect.bind(this);
    }

    initializeSelect(id) {
        var elems = document.getElementById(id);
        var instances = M.FormSelect.init(elems); 
    }

    componentDidMount(){
        this.initializeSelect(this.props.id)
    }

    render() {
        if(this.props.classes.length > 0) {
            var classes = this.props.classes
        }else{
            var classes = 'input-field col s4 m2'
        }

        if(this.props.options.length > 0) {
            var count = 0
            var options = this.props.options.map(option => {
                count++
                return (
                <option key={count}>{option}</option>
                )
            });
        }

        return(
            <div className={classes} alt={this.propsalt}>
                <select id={this.props.id}>
                    {options}
                </select>
            </div>
        );
    }
}

export default Select  