import React from "react"
import axios from "axios";
import chartjs from 'chart.js';
import LoadingOverlay from "react-loading-overlay";
import ChartToInsight from "../insights_components/ChartToInsight";

class InsightsContainer extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            dataToChart: [],
            worstDay: '',
            worstDayLastMonth: [],
            instalationDate: ''
        }
    }

    worstDayLastMonth(worstDay){
        if(worstDay.success == false || worstDay.success == true && worstDay.info_data.alertas_soma == null){
            var infoToSet = {
                success: false,
                message: 'Não há nenhuma leitura fora da faixa referente ao mês passado.'
            }

            this.setState({worstDayLastMonth: infoToSet});
            return;
        }

        var dia = worstDay.info_data.dia.split('-')[2];

        const date = new Date(worstDay.info_data.dia);  
        const month = date.toLocaleString('pt-BR', { month: 'long' });

        var infoToSet = {
            success: true,
            month: month,
            sumOfAlerts: worstDay.info_data.alertas_soma,
            day: dia,
        }

        this.setState({worstDayLastMonth: infoToSet});
    }

    worstDayConfig(worstDay){
        if(worstDay == 'sunday'){
            this.setState({worstDay: 'Domingo'})
        }else if(worstDay == 'monday'){
            this.setState({worstDay: 'Segunda-feira'})
        }else if(worstDay == 'tuesday'){
            this.setState({worstDay: 'Terça-feira'})
        }else if(worstDay == 'wednesday'){
            this.setState({worstDay: 'Quarta-feira'})
        }else if(worstDay == 'thursday'){
            this.setState({worstDay: 'Quinta-feira'})
        }else if(worstDay == 'friday'){
            this.setState({worstDay: 'Sexta-feira'})
        }else if(worstDay == 'saturday'){
            this.setState({worstDay: 'Sábado'})
        }
    }

    componentDidMount(){
        var url_to_request = '/api/v2/insight_measurements_above_below/' + this.props.sensor_id
        var response = axios.get(url_to_request,
            {
                headers: {
                    "Authorization": this.props.token
                }
            })
            .then(response => {
                if(response.data.success == true){
                    var instalationDate = response.data.instalation_date.split('-');
                    instalationDate = instalationDate[2].split(' ')[0] + '/' + instalationDate[1] + '/' + instalationDate[0]
                    this.setState({instalationDate: instalationDate})
                }
                
                this.setState({loading: false, dataToChart: response.data})
                this.worstDayConfig(response.data.worst_day)
            })
            .catch(error => {
                return Promise.reject(error);
            });

        var url_to_request = '/api/v2/insight_measurements_last_month/' + this.props.sensor_id
        var response = axios.get(url_to_request,
            {
                headers: {
                    "Authorization": this.props.token
                }
            })
            .then(response => {
                this.worstDayLastMonth(response.data);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }

    render(){
        return(
            <>
                <div className={'row'}>
                    <div className={'col s12'}>
                        <div className={"card insight-section"}>
                            <h4 className={'center-align'}>Entenda melhor o comportamento deste sensor</h4>
                            <h2 className={'center-align'}>Quantidade de leituras fora da faixa por dia da semana desde a instalação do sensor ({this.state.instalationDate})</h2>
                            {this.state.loading == false ? 
                                <>
                                    <ChartToInsight data={this.state.dataToChart}></ChartToInsight>
                                    <h2 className={'center-align'}>Com base no histórico o dia mais crítico é {this.state.worstDay}</h2>
                                    {this.state.worstDayLastMonth.success ? <h2 className={'center-align'}>O pior dia de {this.state.worstDayLastMonth.month} foi dia {this.state.worstDayLastMonth.day} com {this.state.worstDayLastMonth.sumOfAlerts} leituras fora da faixa. </h2> : <h2 className={'center-align'}>{this.state.worstDayLastMonth.message}</h2>}
                                </>
                                : null
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default InsightsContainer
