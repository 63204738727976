import React from "react"
import DateFilter from "../DateFilter"
import AmbienteList from "../ambientes/AmbienteList";
import AmbienteSidebar from "../ambientes/AmbienteSidebar";
import axios from "axios";

const CancelToken = axios.CancelToken;
var cancel;

export function fetchData(company, year, month, token, isEnergy) {
    cancel && cancel()

    let url = '/api/v2/baus/data/' + company + '/' + year + '/' + month;
    if(isEnergy){
        url = '/api/v2/ambientes/data/' + company + '/' + year + '/' + month;
    }

    return axios.get(url,
        {
            headers: {
                "Authorization": token
            },
            cancelToken: new CancelToken(
                function executor(c) {
                    cancel = c;
                }
            )
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

class AmbientesContainer extends React.Component {

    constructor(props) {
        super(props);
        let now = new Date();
        this.state = {
            month: now.getMonth() + 1,
            year: now.getYear() + 1900,
            isLoading: false,
            ambientesData: null
        };
    };

    requestData = () => {

        this.setState({
            isLoading: true,
            ambientesData: null
        });

        fetchData(this.props.company, this.state.year, this.state.month, this.props.token, this.props.is_energy)
            .then(response => {
                this.setState({
                    isLoading: false,
                    ambientesData: response.data
                });
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.log(error);
                    this.setState({isLoading: false});
                }
            });

    }

    onChangeDateHandler = (newMonth, newYear) => {
        this.setState({
            month: newMonth,
            year: newYear
        })
    };

    componentDidMount() {
        this.requestData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.month !== prevState.month) {
            this.requestData();
        }
    }

    render() {

        let ambientesData = this.state.ambientesData !== null ? this.state.ambientesData.ambientes : [];
        let desperdicioData = this.state.ambientesData !== null ? this.state.ambientesData.desperdicio : null;

        return (
            <div className="row" style={{position: 'relative', marginBottom: '20px'}}>

                <div className="col s12">
                    <DateFilter onChangeDateHandler={this.onChangeDateHandler}/>
                </div>

                <div className="">
                    <AmbienteList ambientesData={ambientesData} 
                        isLoading={this.state.isLoading} 
                        isEnergy={this.props.is_energy} 
                        translate={this.props.translate}/>

                    <AmbienteSidebar desperdicioData={desperdicioData} 
                        translate={this.props.translate}
                        isLoading={this.state.isLoading} 
                        month={this.state.month} 
                        year={this.state.year} 
                        company={this.props.company} 
                        has_fixos={this.props.has_fixos} 
                        isEnergy={this.props.is_energy} 
                        currentUserIsOperator={this.props.current_user_is_operator} />
                </div>

            </div>
        );
    }
}

export default AmbientesContainer
