import React from "react"
import LoadingOverlay from "react-loading-overlay";
import Select from '../Select';
import Input from '../Input';
import axios from 'axios';
import SmeList from '../sme_messages/SmeList'
import SmeNewMessage from "./SmeNewMessage";

class SmeMenu extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            messagesData: [],
            companies: [],
            loading: true,
            stats: []
        }
    }

    gettingMessages = () => {
        this.setState({loading: true})

        var typeMessage = '';
        var valueInput = document.getElementById('buscar').value;
        
        var valueSelect = document.querySelector('.select-wrapper > ul > li.selected > span').innerHTML
        var valueSelectSend = document.getElementById('envioFiltro').value
        var valueOfDate = document.getElementById('datepicker').value

        if(valueSelectSend == 'Todos'){
            var paramSend = '&filter=all'
        }else if(valueSelectSend == 'E-mail'){
            var paramSend = '&filter=email'
        }else if(valueSelectSend == 'App'){
            var paramSend = '&filter=app'
        }
        
        if(valueSelect == 'Título'){
            typeMessage = 'title';
        }else if(valueSelect == 'Usuário'){
            typeMessage = 'user';
        }else if(valueSelect == 'Empresa'){
            typeMessage = 'company';
        }else if(valueSelect == 'Descrição'){
            typeMessage = 'body';
        }else if(valueSelect == 'Todas'){
            typeMessage = 'recent';
            valueInput= true;
        }

        var url_to_request = '/api/v2/sme_messages_search?' + typeMessage + '=' + valueInput + paramSend + '&data=' + valueOfDate;
        var response = axios.get(url_to_request,
                {
                    headers: {
                        "Authorization": this.props.token
                    }
                })
                .then(response => {
                    this.setState({messagesData: response.data.messages}); 
                    this.setState({loading: false}) 
                })
                .catch(error => {
                    return Promise.reject(error);
                });
    }

    gettingDefaultMessages = () => {
        var url_to_request = '/api/v2/sme_messages_search?recent=true';
        return axios.get(url_to_request,
                {
                    headers: {
                        "Authorization": this.props.token
                    }
                })
                .then(response => {
                    this.setState({messagesData: response.data.messages});
                    this.setState({loading: false}) 
                })
                .catch(error => {
                    return Promise.reject(error);
                });
    }

    gettingStats = () => {
        var url_to_request = '/api/v2/getting_stats_type';
        return axios.get(url_to_request,
                {
                    headers: {
                        "Authorization": this.props.token
                    }
                })
                .then(response => {
                    this.setState({stats: response.data});
                })
                .catch(error => {
                    return Promise.reject(error);
                });
    }

    componentDidMount(){
        this.gettingDefaultMessages();
        this.gettingStats();

        var elems = document.getElementById('datepicker');
        var instances = M.Datepicker.init(elems);
    }

    render() {
        let options = ['Título', 'Usuário', 'Empresa', 'Descrição', 'Todas'];
        let sendType = ['Todos', 'E-mail', 'App'];

        return(
            <div className="filtro row messages">
                <div className="filtro row">
                    <Input
                        type={'text'}
                        placeholder={'Escreva o que busca aqui'}
                        id={'buscar'}
                        alt={'Campo que precisa ser preenchido para realizar busca'}
                        classes
                        label={'Escreva o que busca aqui'}
                    />

                    <Select
                        id={'tipo-filtro'}
                        alt={'Seletor para filtro de mensagem'}
                        classes
                        options={options}
                    />

                    <Select
                        id={'envioFiltro'}
                        alt={'Seletor para filtro de como a mensagem foi enviada'}
                        classes
                        options={sendType}
                    />

                    <Input
                        type={'text'}
                        placeholder={'Quando?'}
                        id={'datepicker'}
                        alt={'Campo para filtrar por data'}
                        label={'Quando?'}
                        classes={'input-field col s3 m2 datepicker'}
                    />

                    <a className="btn left" style={{marginTop: 10 + 'px'}} onClick={this.gettingMessages}>Buscar mensagens</a>
                </div>
                <div className="message-list__container">
                    <LoadingOverlay
                            active={this.state.loading}
                            spinner
                            color="#40BDA7"
                            text='Carregando mensagens'/>
                    <SmeList
                        messagesData={this.state.messagesData}
                        token={this.props.token}
                        type_stats={this.state.stats}
                    />
                </div>

                <a className="btn-floating btn-large waves-effect waves-light modal-trigger" href="#modal-new-message" id="new-message"><i className="material-icons">add</i></a>

                <SmeNewMessage
                    classes={'modal'}
                    id={'modal-new-message'}
                    token={this.props.token}
                />
            </div>

        );
    }
}

export default SmeMenu  