import React from "react"

class TextArea extends React.Component {
    constructor(props){
        super(props)
    }

    render() {
        if(this.props.id.length > 0 ){
            var id = this.props.id
        }else{
            var id = 'input-' + this.props.type
        }

        if(this.props.rows > 0){
            var rows = this.props.rows
        }else{
            var rows = 5
        }
        
        if(this.props.classes.length > 0) {
            var classes = this.props.classes
        }else{
            var classes = 'input-field col s5 m4'
        }

        if(this.props.label){
            var label = this.props.label;
            var placeholder = '';
        }

        return(
            <div className={classes} alt={this.props.alt}>
                <label htmlFor={id}> {label} </label>
                <textarea id={id} className="materialize-textarea"></textarea>
            </div>
        );
    }
}

export default TextArea  