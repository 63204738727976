import axios from "axios";

const CancelTokenDoorStatusData = axios.CancelToken;
var cancelDoorStatusData;
const fetchDoorStatusData = function (sensor_id, initial_date, final_date, token) {
    cancelDoorStatusData && cancelDoorStatusData()

    return axios.get('/api/v2/door-status/' + sensor_id + '/' + initial_date + '/' + final_date,
        {
            headers: {
                "Authorization": token
            },
            cancelToken: new CancelTokenDoorStatusData(
                function executor(c) {
                    cancelDoorStatusData = c;
                }
            )
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

export {fetchDoorStatusData}