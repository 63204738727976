import React from "react"

class MoneyDisplay extends React.Component {

    render() {

        let value = this.props.value;
        value = value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});

        return (

            <span className="money-display-component">{value}</span>

        );
    }
}

export default MoneyDisplay;
