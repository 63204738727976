import React from "react";
import PaletteColors from "../shared/PaletteColors";
import {Line} from "react-chartjs-2";
import moment from "moment"
import LoadingOverlay from "react-loading-overlay";
import * as Requests from "../relatorio_cadeia/RelatorioCadeiaRequests";

class AnaliseTemporalChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            chartData: [],
            isVisible: false
        };
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.filterParams.dateInicio && this.props.filterParams.dateFim) {

            if (!this.state.isVisible) {
                this.setState({isVisible: true})
            }

            if (this.props.filterParams.dateInicio != prevProps.filterParams.dateInicio ||
                this.props.filterParams.dateFim != prevProps.filterParams.dateFim ||
                this.props.filterParams.selectedSensorId != prevProps.filterParams.selectedSensorId ||
                this.props.selectedWeeks != prevProps.selectedWeeks) {

                this.requestDataForChart()

            }

        }
    }

    requestDataForChart = () => {

        if (this.props.filterParams.dateInicio && this.props.filterParams.dateFim) {

            this.setState({isLoading: true})
            Requests.fetchChartDataHourly(
                this.props.filterParams.selectedSensorId,
                this.props.filterParams.dateInicio,
                this.props.filterParams.dateFim,
                this.props.selectedWeeks,
                this.props.token)
                .then(result => {

                    this.setState({
                        chartData: result.data,
                        isLoading: false
                    })

                })
                .catch(err => {
                    this.setState({
                        isLoading: false
                    })
                });
        }

    };

    render() {

        moment.locale("pt-BR")

        let chartData = {
            labels: [],
            datasets: [],
            selectedWeeks: this.props.selectedWeeks
        };

        this.state.chartData.forEach(etapa => {
            if (etapa.data) {
                for (const [key, value] of Object.entries(etapa.data)) {
                    chartData.labels.push(key);
                }
            }
        });

        chartData.labels = chartData.labels.sort((a, b) => {
            return a.localeCompare(b)
        });

        this.state.chartData.forEach((etapa, index) => {

            if (etapa.data) {

                let dados = [];

                chartData.labels.forEach(key => {
                    if (etapa.data[key]) {
                        dados.push(parseFloat(etapa.data[key]));
                    } else {
                        dados.push(null);
                    }
                });

                let colorForIndex = PaletteColors.getColor(index);

                let labelToUse = "Período selecionado";
                if (etapa.week_offset != 0) {
                    if (etapa.week_offset == 1) {
                        labelToUse = "1 semana antes"
                    } else {
                        labelToUse = etapa.week_offset + " semanas antes"
                    }
                }

                chartData.datasets.push(
                    {
                        label: labelToUse,
                        data: dados,
                        backgroundColor: "transparent",
                        borderColor: colorForIndex,
                        pointHoverBackgroundColor: colorForIndex,
                        spanGaps: true
                    }
                )

            }

        });

        let chartOptions = {
            maintainAspectRatio: true,
            scales: {
                xAxes: [{
                    display: false,
                    scaleLabel: {
                        display: true
                    },
                    ticks: {
                        display: true
                    }
                }],
                yAxes: [{
                    display: true,
                    scaleLabel: {
                        display: false,
                        labelString: 'HH:mm'
                    }
                }]
            },
            layout: {
                padding: {
                    top: 20,
                    right: 20,
                    bottom: 40,
                    left: 20
                }
            },
            tooltips: {
                enabled: true,
                mode: 'single',
                callbacks: {
                    title:  function (tooltipItems, data) {
                        var datasetIndex = tooltipItems[0].datasetIndex;
                        var datasetLabel = data.datasets[datasetIndex].label;
                        return datasetLabel
                    },
                    label: function (tooltipItems, data) {

                        var datasetIndex = tooltipItems.datasetIndex;
                        var weekOffset = data.selectedWeeks[datasetIndex];

                        let tooltipToUse = moment(tooltipItems.xLabel)
                            .subtract(weekOffset, 'weeks')
                            .format('DD/MM/YYYY HH:mm');

                        return tooltipToUse;
                    }
                }
            }
        };

        let chart = null;
        if (this.state.chartData && this.state.chartData[0] && this.state.chartData[0].data && this.state.isLoading == false) {
            chart = (<Line
                data={chartData}
                options={chartOptions}
            />)
        }

        return (

            <div>

                {this.state.isVisible ? (

                    <div className="analise-temporal-chart-wrapper">

                        <div className="loading-wrapper" style={{display: this.state.isLoading ? 'block' : 'none'}}>
                            <LoadingOverlay
                                active={this.state.isLoading}
                                spinner
                                color="#1ABB9C"
                                text='Carregando dados para o gráfico...'/>
                        </div>

                        {chart}
                    </div>

                ) : null
                }

            </div>
        );
    }
}

export default AnaliseTemporalChart
