import React from "react";
import moment from "moment";

class TimelineAlertItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_showing_sensors: false,
      is_showing_alike_alerts: false
    };
  }

  handleToggleButton = () => {
    this.setState({
      is_showing_sensors: !this.state.is_showing_sensors
    });
  };

  handleAlikeAlertsButton = () => {
    this.setState({
      is_showing_alike_alerts: !this.state.is_showing_alike_alerts
    });
  };

  render() {
    moment.locale("pt-BR");
    let alertTitle = "Alerta de sensor";
    let className = "timeline_alert";
    let dateMeasurement = moment.utc(this.props.alert.sensor.data_temperatura);
    let dateAlert = moment(this.props.alert.data);

    let notDisconnectedMessage =
      this.props.alert.tipo == "off" ? null : (
        <div className="timeline_temperatura">
          <span>{this.props.translate.temperature}: {this.props.alert.sensor.temperatura}°C</span> às{" "}
          {dateMeasurement.format("HH:mm")} de{" "}
          {dateMeasurement.format("DD/MM/YYYY")}
        </div>
      );

    switch (this.props.alert.tipo) {
      case "max":
      case "temp_max":
        alertTitle = "Alerta de temperatura máxima";
        className = "timeline_alert_max";
        break;
      case "humi_max":
        alertTitle = "Alerta de umidade máxima";
        className = "timeline_alert_max";
        break;
      case "min":
      case "temp_min":
        alertTitle = "Alerta de temperatura mínima";
        className = "timeline_alert_min";
        break;
      case "humi_min":
        alertTitle = "Alerta de umidade mínima";
        className = "timeline_alert_min";
        break;
      case "off":
      case "offline":
        alertTitle = "Alerta de desconexão";
        className = "timeline_alert_off";
        break;
      case "power":
      case "battery":
      case "power_supply":
        alertTitle = "Alerta de bateria";
        className = "timeline_alert_power";
        break;
      default:
        this.props.alert.mensagem =
          "Esta mensagem não possui descrição pois é uma mensagem do sistema antigo de alertas.";
    }

    let btnPhones = null;
    let timelinePhones = null;
    if (className != "timeline_alert" && this.props.alert.telefones.length) {
      btnPhones = (
        <div className="right-align mensagem-enviada">
          <button className="btn right" onClick={this.handleToggleButton}>
            Ver mensagem enviada
          </button>
        </div>
      );

      let telefones = "<p>Nenhum telefone enviado</p>";
      let countTelefones = 0;
      telefones = this.props.alert.telefones.map(telefone => {
        countTelefones++;
        return (
          <div
            key={this.props.alert.id + "_" + telefone.id + "_" + countTelefones}
          >
            {telefone.telefone} - {telefone.nome}
          </div>
        );
      });

      timelinePhones = (
        <div
          id={"timeline_phones_" + this.props.alert.id}
          className={
            "collapse pull-left container timeline_phones " +
            (this.state.is_showing_sensors ? "" : "hide")
          }
        >
          <p className="timeline_message">{this.props.alert.mensagem}</p>
          <p>Telefones enviados</p>

          {telefones}
        </div>
      );
    }

    let alerts_alike = this.props.alert.alerts_in_this_quarter.alerts.map(
      alert => {
        if (alert.id != this.props.alert.id) {
          return (
            <div key={alert.id}>
              <span className="alert-alike-type">{alert.type}</span> -{" "}
              {moment(alert.date).format("DD/MM/YYYY HH:mm")}
            </div>
          );
        } else {
          return null;
        }
      }
    );

    return (
      <div className={"alert-item " + className + " col s12"}>
        <div className="container">
          <div className="timeline_title_area">
            <div className="timeline_title">{alertTitle}</div>
            <div
              className="timeline_date"
              title={dateAlert.format("DD/MM/YYYY HH:mm")}
            >
              {dateAlert.fromNow()}
            </div>
          </div>
        </div>

        <div className="pull-left container">
          <div className="timeline_sensor">
            <span>Alerta gerado: </span>
            às {" "}
            {dateAlert.format("HH:mm")} 
            {" "} de {" "}
            {dateAlert.format("DD/MM/YYYY")}
            <br/>
            <span>{this.props.alert.sensor.label}</span> |{" "}
            {this.props.alert.sensor.temperatura_min}°C à{" "}
            {this.props.alert.sensor.temperatura_max}°C
          </div>

          {this.props.alert.alerts_in_this_quarter.count > 1 ? (
            <div>
              <button
                className="btn btn-danger left btn-alike-alerts"
                onClick={this.handleAlikeAlertsButton}
              >
                {this.props.translate.alerts_with_similar_times}:{" "}
                {this.props.alert.alerts_in_this_quarter.count - 1}
              </button>

              <div
                id={"timeline_alerts_alike_" + this.props.alert.id}
                className={
                  "collapse pull-left container timeline_phones " +
                  (this.state.is_showing_alike_alerts ? "" : "hide")
                }
              >
                {alerts_alike}
              </div>
            </div>
          ) : null}

          {notDisconnectedMessage}
          {btnPhones}
          {timelinePhones}
        </div>
      </div>
    );
  }
}

export default TimelineAlertItem;
