import React from "react";
import EtapaCadeia from "./EtapaCadeia";
import RelatorioCadeiaChartDygraph from "./RelatorioCadeiaChartDygraph";
import RelatorioCadeiaOverviewContainer from "./RelatorioCadeiaOverviewContainer";

class RelatorioCadeiaPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            etapas: [
                {
                    selected_company_id: this.props.companies[0].id,
                    selected_sensor_id: this.props.companies[0].sensors[0].id,
                    date_inicio: null,
                    date_final: null,
                    bar_info: null,
                    overview_info: null,
                    table_info: null
                }
            ],
            isLoadingChartData: false
        };
    };

    addEtapa = () => {

        this.setState({
            etapas: [...this.state.etapas, {
                selected_company_id: this.props.companies[0].id,
                selected_sensor_id: this.props.companies[0].sensors[0].id,
                date_inicio: this.state.etapas[0] ? this.state.etapas[0].date_inicio : null,
                date_final: this.state.etapas[0] ? this.state.etapas[0].date_final : null
            }]
        });
    };

    removeEtapa = (index) => {

        let etapasAux = this.state.etapas;
        etapasAux.splice(index, 1);

        this.setState({
            etapas: etapasAux
        });
    };

    changeDataFromEtapa = (data, index) => {
        let currentEtapas = this.state.etapas;

        if (data.selected_company_id) currentEtapas[parseInt(index)].selected_company_id = data.selected_company_id;
        if (data.selected_sensor_id) currentEtapas[parseInt(index)].selected_sensor_id = data.selected_sensor_id;
        if (data.date_inicio) currentEtapas[parseInt(index)].date_inicio = data.date_inicio;
        if (data.date_final) currentEtapas[parseInt(index)].date_final = data.date_final;
        if (data.bar_info) currentEtapas[parseInt(index)].bar_info = data.bar_info;
        if (data.bar_info_humidity) currentEtapas[parseInt(index)].bar_info_humidity = data.bar_info_humidity;
        if (data.chart_data) currentEtapas[parseInt(index)].chart_data = data.chart_data;
        if (data.chart_data_humidity) currentEtapas[parseInt(index)].chart_data_humidity = data.chart_data_humidity;
        if (data.overview_info) currentEtapas[parseInt(index)].overview_info = data.overview_info;
        if (data.table_info) currentEtapas[parseInt(index)].table_info = data.table_info;
        if (data.table_info_humidity) currentEtapas[parseInt(index)].table_info_humidity = data.table_info_humidity;

        this.setState({
            etapas: currentEtapas
        });
    };

    changeLoadingChartDataStatus = (isLoading) => {
        this.setState({
            isLoadingChartData: isLoading
        });
    };

    render() {

        let currentPosition = 0;
        let etapas = this.state.etapas.map((etapa, index) => {
            currentPosition++;
            return <EtapaCadeia companies={this.props.companies}
                                position={currentPosition}
                                key={currentPosition}
                                isLast={index == this.state.etapas.length - 1}
                                index={index}
                                bar_info={etapa.bar_info}
                                bar_info_humidity={etapa.bar_info_humidity}
                                overview_info={etapa.overview_info}
                                table_info={etapa.table_info}
                                table_info_humidity={etapa.table_info_humidity}
                                selected_company_id={etapa.selected_company_id}
                                selected_sensor_id={etapa.selected_sensor_id}
                                date_inicio={etapa.date_inicio}
                                date_final={etapa.date_final}
                                addEtapaHandler={this.addEtapa}
                                removeEtapaHandler={this.removeEtapa}
                                token={this.props.token}
                                changeDataHandler={this.changeDataFromEtapa}
                                changeLoadingChartDataStatusHandler={this.changeLoadingChartDataStatus}
                                translate={this.props.translate}/>
        });

        return (
            <div className="relatorio-cadeia-page">
                {etapas}
                <RelatorioCadeiaChartDygraph etapas={this.state.etapas}
                                      companies={this.props.companies}
                                      isLoading={this.state.isLoadingChartData}
                                      currentUserIsOperator={this.props.current_user_is_operator}
                                      changeChartDataHandler={this.changeChartDataHandler}
                                      translate={this.props.translate}/>

            </div>
        );
    }

}

export default RelatorioCadeiaPage
