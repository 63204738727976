import React from "react";
import RelatorioCadeiaOverviewItem from "./RelatorioCadeiaOverviewItem";

class RelatorioCadeiaOverviewContainer extends React.Component {

    render() {

        let items = this.props.etapas.map((etapa, index) => {
            if(etapa.overview_info){
                return <RelatorioCadeiaOverviewItem key={index}
                    overview_info={etapa.overview_info} translate={this.props.translate} />
            }
        });

        return (
            <div className="relatorio-cadeia-overview-container container">
                <div className="row">
                {items}
                </div>
            </div>
        );
    }
}

export default RelatorioCadeiaOverviewContainer
