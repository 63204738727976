import React from "react";
import PaletteColors from "../shared/PaletteColors";
import Dygraph from 'dygraphs';
import moment from "moment"
import LoadingOverlay from "react-loading-overlay";
import * as Requests from "../relatorio_cadeia/RelatorioCadeiaRequests";

class AnaliseTemporalChartDygraph extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            chartData: [],
            isVisible: false,
            height: props.height,
            width: props.width
        };
    };

    componentWillMount() {
        this.setState({height: window.innerHeight, width: window.innerWidth});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.filterParams.dateInicio && this.props.filterParams.dateFim) {

            if (!this.state.isVisible) {
                this.setState({isVisible: true})
            }

            if (this.props.filterParams.dateInicio != prevProps.filterParams.dateInicio ||
                this.props.filterParams.dateFim != prevProps.filterParams.dateFim ||
                this.props.filterParams.selectedSensorId != prevProps.filterParams.selectedSensorId ||
                this.props.selectedWeeks != prevProps.selectedWeeks) {

                this.requestDataForChart()

            }

        }

        if (this.state.chartData != prevState.chartData && !this.state.isLoading) {
            this.generateDygraph();
        }
    }

    requestDataForChart = () => {

        if (this.props.filterParams.dateInicio && this.props.filterParams.dateFim) {

            this.setState({isLoading: true})
            Requests.fetchChartDataHourly(
                this.props.filterParams.selectedSensorId,
                this.props.filterParams.dateInicio,
                this.props.filterParams.dateFim,
                this.props.selectedWeeks,
                this.props.token)
                .then(result => {

                    this.setState({
                        chartData: result.data,
                        isLoading: false
                    })

                })
                .catch(err => {
                    this.setState({
                        isLoading: false
                    })
                });
        }

    };

    generateDygraph() {

        let dygraphLabels = ['Data'];

        let dygraphData = this.state.chartData.map(chart_data => {
            chart_data[0] = new Date(chart_data[0]);
            return chart_data
        });

        this.props.selectedWeeks.forEach(etapa => {
            if (etapa == 0) {
                dygraphLabels.push('Temperatura')
            } else if (etapa == 1) {
                dygraphLabels.push('1 semana antes')
            } else {
                dygraphLabels.push(etapa + ' semanas antes')
            }
        });

        var showRangeSelector = false;
        if (this.state.width < 1024) {
            showRangeSelector = true
        }


        var coresPaleta = PaletteColors.getColors();
        coresPaleta = coresPaleta.slice(0, dygraphLabels.length - 1);
        dygraphLabels.push('Temperatura mínima');
        dygraphLabels.push('Temperatura máxima');
        dygraphData = dygraphData.map(data => {
            data.push(this.props.minTemperature);
            data.push(this.props.maxTemperature);
            return data;
        });
        // Adicionado azul e vermelho absolutos para temperatura mínima e máxima
        coresPaleta.push("#00f");
        coresPaleta.push("#f00");


        new Dygraph(
            'dygraph-chart',
            dygraphData,
            {
                colors: coresPaleta,
                labelsDiv: document.getElementById('temperature-chart-labels'),
                labelsSeparateLines: true,
                legend: 'always',
                labels: dygraphLabels,
                hideOverlayOnMouseOut: false,
                connectSeparatedPoints: true,
                legendFormatter: function legendFormatter(data) {

                    var counter = 0;
                    var html = '';
                    if (data.x == null) {
                        data.series.forEach(function (series) {
                            if (!series.isVisible || counter >= (data.series.length - 2))
                                return;

                            var labeledData = series.labelHTML;
                            counter++;
                            html += ' ' + series.dashHTML + ' ' + labeledData + ' ';
                        });
                        return html;
                    }

                    data.series.forEach(function (series) {
                        if (!series.isVisible) {
                            return;
                        }

                        if (series.yHTML) {
                            var labeledData = series.labelHTML + ': <b>' + series.yHTML + '</b>';
                            if (series.isHighlighted) {
                                labeledData = '<b>' + labeledData + '</b>';
                            }
                            counter++;
                            html += ' ' + series.dashHTML + ' ' + labeledData + ' ';
                        }

                    });

                    html += '<div class="data">' + this.getLabels()[0] + ': <b>' + new Intl.DateTimeFormat('pt-BR', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                        hour12: false,
                        timeZone: 'America/Sao_Paulo'
                    }).format((new Date(data.xHTML))) + '</b></div>';
                    return html;
                },
                showRangeSelector: showRangeSelector,
                rangeSelectorPlotStrokeColor: 'rgba(64, 189, 167, 1)',
                rangeSelectorPlotFillColor: 'rgba(64, 189, 167, 0.5)',
                rangeSelectorPlotFillGradientColor: 'rgba(64, 189, 167, 0.5)',
                rangeSelectorForegroundStrokeColor: 'rgba(100, 100, 100, 0.7)'
            }
        )
    }

    render() {

        moment.locale("pt-BR")

        return (

            <div>

                {this.state.isVisible ? (

                    <div className="analise-temporal-chart-wrapper">

                        <div className="loading-wrapper" style={{display: this.state.isLoading ? 'block' : 'none'}}>
                            <LoadingOverlay
                                active={this.state.isLoading}
                                spinner
                                color="#1ABB9C"
                                text='Carregando dados para o gráfico...'/>
                        </div>

                        <div id='temperature-chart-labels' style={{width: '100%'}}></div>
                        <div id='dygraph-chart' style={{width: '100%'}}></div>
                    </div>

                ) : null
                }

            </div>
        );
    }
}

export default AnaliseTemporalChartDygraph
