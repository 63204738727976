import React from 'react';
import GoogleMapReact from 'google-map-react';
import MapVehicleMarker from './MapVehicleMarker';
import MapVehicleClusterMarker from './MapVehicleClusterMarker';
import MapCompanyMarker from './MapCompanyMarker';
import LoadingOverlay from "react-loading-overlay";
import supercluster from 'points-cluster';

// Return map bounds based on list of places
const getMapBounds = (map, maps, clusters, companies, markers) => {
    const bounds = new maps.LatLngBounds();

    clusters.forEach((cluster) => {
        bounds.extend(new maps.LatLng(
            cluster.props.lat,
            cluster.props.lng
        ));
    });

    companies.forEach((company) => {
        bounds.extend(new maps.LatLng(
            company.props.lat,
            company.props.lng
        ));
    });

    markers.forEach((marker) => {
        bounds.extend(new maps.LatLng(
            marker.props.lat,
            marker.props.lng
        ));
    });

    return bounds;
};

// Re-center map when resizing the window
const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
        maps.event.addDomListener(window, 'resize', () => {
            map.fitBounds(bounds);
        });
    });
};

// Fit map to its bounds after the api is loaded
const apiIsLoaded = (map, maps, clusters, companies, markers) => {
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, clusters, companies, markers);

    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
};

const MAP = {
    defaultZoom: 1,
    defaultCenter: {
        lat: -13.569827,
        lng: -55.388927
    },
    options: {
        maxZoom: 25,
    },
};

class MapContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mapOptions: {
                center: MAP.defaultCenter,
                zoom: MAP.defaultZoom,
            },
            clusters: []
        };
    };

    getClusters = props => {

        const clusters = supercluster(props.vehicles, {
            minZoom: 0,
            maxZoom: 25,
            radius: 48,
        });

        return clusters(this.state.mapOptions);
    };

    createClusters = props => {
        this.setState({
            clusters: this.state.mapOptions.bounds
                ? this.getClusters(props).map(({wx, wy, numPoints, points}) => ({
                    lat: wy,
                    lng: wx,
                    numPoints,
                    id: `${numPoints}_${points[0].id}`,
                    points,
                }))
                : [],
        });
    };

    handleMapChange = ({center, zoom, bounds}) => {
        this.setState(
            {
                mapOptions: {
                    center,
                    zoom,
                    bounds,
                },
            },
            () => {
                this.createClusters(this.props);
            }
        );
    };

    render() {

        let companies = [];
        this.props.companies.map(company => {
            if (this.props.showCompanies && company.latitude && company.longitude) {
                companies.push(<MapCompanyMarker key={company.id} company={company}
                                                 lat={company.latitude}
                                                 lng={company.longitude}/>)
            }
        });

        let clusters = [];
        let markers = [];
        this.state.clusters.map(item => {

            if (item.numPoints === 1) {
                let vehicle = item.points[0]

                if (this.props.showOff || vehicle.last_info.connected) {
                    markers.push(<MapVehicleMarker key={vehicle.id} vehicle={vehicle}
                                                   lat={vehicle.last_info.latitude}
                                                   lng={vehicle.last_info.longitude}/>)
                }
            } else {

                let connectedVehicles = item.points.filter(vehicle => {
                    return vehicle.last_info.connected
                });

                if (this.props.showOff) {

                    clusters.push(<MapVehicleClusterMarker
                        key={item.id}
                        lat={item.lat}
                        lng={item.lng}
                        counter={item.points.length}
                    />)

                } else if (connectedVehicles.length > 0) {

                    if(connectedVehicles.length === 1) {

                        let onlyConnectedVehicle = item.points.filter(vehicle => {
                            return vehicle.last_info.connected
                        });
                        
                        markers.push(<MapVehicleMarker key={onlyConnectedVehicle[0].id}
                                                       vehicle={onlyConnectedVehicle[0]}
                                                       lat={onlyConnectedVehicle[0].last_info.latitude}
                                                       lng={onlyConnectedVehicle[0].last_info.longitude}/>)

                    } else {
                        clusters.push(<MapVehicleClusterMarker
                            key={item.id}
                            lat={item.lat}
                            lng={item.lng}
                            counter={connectedVehicles.length}
                        />)
                    }

                }
            }

        });

        return (
            // Important! Always set the container height explicitly
            <div style={{height: '100vh', width: '100%'}} className="map-container-map">

                {!this.props.isLoadingVehicles ? (
                    <GoogleMapReact
                        bootstrapURLKeys={{key: "AIzaSyAjgBlJZ9L6XBPO7JbebA0q5XNqAJmpzJs"}}
                        defaultZoom={MAP.defaultZoom}
                        defaultCenter={MAP.defaultCenter}
                        options={MAP.options}
                        onChange={this.handleMapChange}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({map, maps}) => apiIsLoaded(map, maps, clusters, companies, markers)}>
                        {markers}
                        {clusters}
                        {companies}
                    </GoogleMapReact>
                ) : (
                    <div className="loading-wrapper" style={{display: this.props.isLoadingVehicles ? 'block' : 'none'}}>
                        <LoadingOverlay
                            active={this.props.isLoadingVehicles}
                            spinner
                            color="#40BDA7"
                            text='Carregando mapa...'/>
                    </div>
                )}

            </div>
        );
    }
}

export default MapContainer
