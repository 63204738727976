import React from "react"
import { debounce } from "lodash"
import Datetime from 'react-datetime'
import DateTimeField from "@1stquad/react-bootstrap-datetimepicker"
import moment from "moment"


class FilterChartGroup extends React.Component {
    constructor(props) {
        super(props);
        // create a ref to store the textInput DOM element
        this.myRef = React.createRef();
    }
    render() {
        
        moment.locale("pt-BR")
        return (
            <div className="form-inputs">
                <div className="col-xs-6">
                    <label className="string required">Início da viagem</label>
                    <DateTimeField
                        //maxDate={moment()}
                        //defaultText={this.props.inicio}
                        dateTime={moment(this.props.inicio)}
                        inputDisplayFormat={"DD/MM/YYYY HH:mm"}
                        format={"Y-M-D hh:mm:ss"}
                        onChange={this.props.onChangeDateHandlerInicio}
                    />
                </div>
                <div className="col-xs-6">
                    <label className="string required">Final da viagem</label>
                    <DateTimeField
                        //maxDate={moment()}
                        //defaultText={this.props.fim}
                        dateTime={moment(this.props.fim)}
                        inputDisplayFormat={"DD/MM/YYYY HH:mm"}
                        format={"Y-M-D hh:mm:ss"}
                        onChange={this.props.onChangeDateHandlerFim}
                    />
                </div>
            </div>
        )
    }
}
export default FilterChartGroup