import React from "react"

class BtnRelatorioEvolucaoMensal extends React.Component {

    render() {

        let opcaoFixos = null;
        let colNumber = 6;
        if(this.props.hasFixos) {
            colNumber = 4;
            opcaoFixos = (
                <div className="col s12 m4">
                    <a href={"/pdf/general_view_percentage/" + this.props.month + "/" + this.props.year + "/" + this.props.company} className="btn-download-desempenho btn" target="_blank">
                        <span className="fa fa-download"></span>Umidade e temperatura
                    </a>
                </div>
            )
        }

        return (
            <div className="container">
                <div className="container pull-left" style={{position: 'relative', marginBottom: '20px'}}>

                    <h4 className="text-left">Relatórios mensais:</h4>

                    <div className="row">
                        <div className={"col s12 m" + colNumber}>
                            <a href={"/pdf/indicadores_desempenho/" + this.props.month + "/" + this.props.year + "/" + this.props.company} className="btn-download-desempenho btn" target="_blank">
                                <span className="fa fa-download"></span>Indicadores de desempenho
                            </a>
                        </div>

                        {opcaoFixos}

                        <div className={"col s12 m" + colNumber}>
                            <a href={"/pdf/evolucao_semanal/" + this.props.month + "/" + this.props.year + "/" + this.props.company} className="btn-download-desempenho btn" target="_blank">
                                <span className="fa fa-download"></span>Evolução semanal
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default BtnRelatorioEvolucaoMensal
