import React from "react"
import axios from "axios"
import TimelineAlertItem from "./TimelineAlertItem";
import LoadingOverlay from "react-loading-overlay";
import ReactList from 'react-list';
import {Doughnut} from "react-chartjs-2"

const CancelToken = axios.CancelToken;
var cancel;

export function searchAlerts(company, year, month, token) {
    cancel && cancel()

    return axios.get('/api/v2/alerts/timeline/' + company + '/' + year + '/' + month,
        {
            headers: {
                "Authorization": token
            },
            cancelToken: new CancelToken(
                function executor(c) {
                    cancel = c;
                }
            )
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

class TimelineSideAlerts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            alerts: [],
            noData: false,
            offCounter: 0,
            maxCounter: 0,
            minCounter: 0,
            powerCounter: 0
        };
    };

    requestAlerts = () => {

        this.setState({
            isLoading: true,
            alerts: [],
            noData: false,
            offCounter: 0,
            maxCounter: 0,
            minCounter: 0,
            powerCounter: 0
        });

        searchAlerts(this.props.company, this.props.year, this.props.month, this.props.token)
            .then(response => {
                this.setState({
                    isLoading: false,
                    alerts: response.data,
                    noData: !response.data.length
                });

                let offCount = 0;
                let maxCount = 0;
                let minCount = 0;
                let powerCount = 0;

                response.data.forEach(alert => {
                    if (alert.tipo == "max" || alert.tipo == "temp_max") {
                        maxCount += 1;
                    }
                    if (alert.tipo == "humi_max") {
                        maxCount += 1;
                    }
                    if (alert.tipo == "min" || alert.tipo == "temp_min") {
                        minCount += 1;
                    }
                    if (alert.tipo == "humi_min") {
                        minCount += 1;
                    }
                    if (alert.tipo == "off" || alert.tipo == "offline") {
                        offCount += 1;
                    }
                    if (alert.tipo == "power" || alert.tipo == "battery" || alert.tipo == "power_supply") {
                        powerCount += 1;
                    }
                });

                this.setState({
                    offCounter: offCount,
                    maxCounter: maxCount,
                    minCounter: minCount,
                    powerCounter: powerCount
                });

            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.log(error);
                    this.setState({isLoading: false});
                }
            });

    }

    componentDidMount() {
        this.requestAlerts();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.month !== prevProps.month) {
            this.requestAlerts();
        }
    }

    renderItem = (index, key) => {
        return <TimelineAlertItem key={key} alert={this.state.alerts[index]} translate={this.props.translate} />
    }

    render() {

        let titleAlerts = "Alertas emitidos";
        let content = (
            <ReactList
                itemRenderer={this.renderItem}
                length={this.state.alerts.length}
                type='simple'
            />
        );


        let labelsDoughnut = [];
        let dataDoughnut = [];
        let colorDoughnut = [];

        if (this.state.offCounter !== 0) {
            labelsDoughnut.push("Fora de sinal (" + this.state.offCounter + ")");
            dataDoughnut.push(this.state.offCounter);
            colorDoughnut.push("#b5b5b5");
        }

        if (this.state.maxCounter !== 0) {
            labelsDoughnut.push("Acima (" + this.state.maxCounter + ")");
            dataDoughnut.push(this.state.maxCounter);
            colorDoughnut.push("#ff6383");
        }

        if (this.state.minCounter !== 0) {
            labelsDoughnut.push("Abaixo (" + this.state.minCounter + ")");
            dataDoughnut.push(this.state.minCounter);
            colorDoughnut.push("#36a1eb");
        }

        if (this.state.powerCounter !== 0) {
            labelsDoughnut.push("Bateria (" + this.state.powerCounter + ")");
            dataDoughnut.push(this.state.powerCounter);
            colorDoughnut.push("#e3eb88");
        }

        const data = {
            labels: labelsDoughnut,
            datasets: [{
                data: dataDoughnut,
                backgroundColor: colorDoughnut,
                hoverBackgroundColor: colorDoughnut
            }]
        };

        if (this.state.noData) {
            content = (<div className="no-data-timeline"><i className="fa fa-check fa-margin-top" style={{fontSize: 3 + 'em', color: "#40BDA7"}}></i><br/><span style={{fontSize: 20 + 'px', color: "#40BDA7"}}>Parabéns!<br/>Você não possui envio de alertas neste mês.</span></div>);
        }

        return (
            <div className="side-timeline left col s12 m4">

                <h4 className="page-subtitle timeline-page-subtitle">{titleAlerts}</h4>

                {dataDoughnut.length !== 0 ? (
                    <div className="doughnut-wrapper-area">
                        <Doughnut data={data}/>
                    </div>
                ) : null}

                <div className="emmited-alerts-container">

                    <div className="loading-wrapper" style={{display: this.state.isLoading ? 'block' : 'none'}}>
                        <LoadingOverlay
                            active={this.state.isLoading}
                            spinner
                            color="#40BDA7"
                            text='Carregando lista de alertas...'/>
                    </div>

                    {content}

                </div>
            </div>
        );
    }
}


export default TimelineSideAlerts
