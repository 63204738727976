import React from "react";
import moment from "moment";

class AnaliseTemporalSelectWeek extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedWeekNumber: this.props.availableWeeks[0]
        };
    };

    onChange = (event) => {
        const target = event.target;
        this.setState({selectedWeekNumber: target.value})
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.availableWeeks.length &&
            !this.props.availableWeeks.includes(this.state.selectedWeekNumber) &&
            this.state.selectedWeekNumber == prevState.selectedWeekNumber) {
            this.setState({selectedWeekNumber: this.props.availableWeeks[0]})
        }
    }

    render() {

        let optionsWeeks = this.props.availableWeeks.map((weekNumber, index) => {
            return (<option key={index}
                            value={weekNumber}>{weekNumber} semana{weekNumber > 1 ? "s" : ""} antes</option>)
        });

        let startDate = this.props.filterParams.dateInicio ? moment(this.props.filterParams.dateInicio).subtract(this.state.selectedWeekNumber, 'week').format('DD/MM/YYYY') : null;
        let finishDate = this.props.filterParams.dateFim ? moment(this.props.filterParams.dateFim).subtract(this.state.selectedWeekNumber, 'week').format('DD/MM/YYYY') : null;

        return (
            <div className="analise-temporal-period-wrapper">

                {optionsWeeks.length ? (
                    <div className="row">
                        <div className="col s12 m6">
                            <div className="custom-input-wrapper">
                                <div className="custom-input-label">{this.props.translate.add_date_range}</div>
                                <select className="custom-input"
                                        name="selected_week_number"
                                        onChange={this.onChange}
                                        value={this.state.selectedWeekNumber}>
                                    {optionsWeeks}
                                </select>
                            </div>
                        </div>

                        <div className="col s12 m6 analise-temporal-period-container">
                            <div className="analise-temporal-period-items">
                                <div className="analise-temporal-period-piece analise-temporal-period-start">{startDate}
                                </div>
                                <div
                                    className="analise-temporal-period-piece analise-temporal-period-end">{finishDate}</div>
                                <button onClick={() => {
                                    this.props.onWeekAdded(this.state.selectedWeekNumber)
                                }} className={"btn btn-success btn-add-period"}><i className={"fa fa-plus"}></i>
                                </button>
                            </div>
                        </div>

                    </div>
                ) : null}
            </div>
        );
    }

}

export default AnaliseTemporalSelectWeek