import React from "react";
import moment from "moment";

class AnaliseTemporalSelectedWeekList extends React.Component {

    constructor(props) {
        super(props);
    };

    render() {

        let weekMarkup = this.props.selectedWeeks.map(week => {
            if (week !== 0 && this.props.filterParams.dateInicio && this.props.filterParams.dateFim) {

                let startDateCorrected = moment(this.props.filterParams.dateInicio).subtract(week, 'week');
                let endDateCorrected = moment(this.props.filterParams.dateFim).subtract(week, 'week');

                return (
                    <div className={"analise-temporal-period-wrapper"} key={week}>
                        <div className="col s12 m6 analise-temporal-period-container">
                            <div className="analise-temporal-period-items">
                                <div className="analise-temporal-period-piece full-width text-left">{week} semana{week > 1 ? "s" : ""} antes</div>
                            </div>
                        </div>
                        <div className="col s12 m6 analise-temporal-period-container">
                            <div className="analise-temporal-period-items">
                                <div className="analise-temporal-period-piece analise-temporal-period-start">{startDateCorrected.format('DD/MM/YYYY')}</div>
                                <div className="analise-temporal-period-piece analise-temporal-period-end">{endDateCorrected.format('DD/MM/YYYY')}</div>
                                <button onClick={() => {this.props.onWeekRemoved(week)}} className={"btn btn-danger btn-add-period"}><i className={"fa fa-minus"}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        });

        return (
            <div className="row">

                {weekMarkup}

            </div>
        );
    }

}

export default AnaliseTemporalSelectedWeekList
