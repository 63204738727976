import React from "react"
import LoadingOverlay from "react-loading-overlay";

class SmeMessageDetails extends React.Component {
    constructor(props){
        super(props);
    }

    initializeModal = (id) => {
        var elems = document.getElementById(id);
        var instances = M.Modal.init(elems);   
    }

    componentDidMount(){
        var id = this.props.id
        this.initializeModal(id)
    }

    render() {
        if(this.props.classes.length > 0){
            var classes = this.props.classes
        }else{
            var classes = 'modal'
        }

        if(this.props.id.length > 0){
            var id = this.props.id
        }

        if(this.props.messageDetails.usersNames){
            var names = this.props.messageDetails.usersNames;
            var usersNames = '';
    
            usersNames = this.props.messageDetails.usersNames.map((item, index) => (
                <li className="new badge user-name" key={index} >{item}</li>
            ))  
        }

        if(this.props.messageDetails.when_was_sended){
            var date = this.props.messageDetails.when_was_sended
            date = date.split('-');
            date = date[2].slice(0, 2) + '/' + date[1] + '/' + date[0];
        }

        if(this.props.messageDetails.how_was_sended){
            var type = this.props.messageDetails.how_was_sended
            if(type == 1){
                var type = <span className="new badge email">E-mail</span>
            }else if(type == 2){
                var type = <span className="new badge app">App</span>
            }else if(type == 3){
                var type =  <div>
                                <span className="new badge email">E-mail</span>
                                <span className="new badge app">App</span>
                            </div>
            }
        }

        return(
            
            <div className={classes} id={id}>
                <div className="modal-content">
                    {type}
                    <small>Quem enviou</small>
                    <h5> {this.props.messageDetails.senderName} </h5>
                    
                    <small>Mensagem enviada para</small>
                    <ul className="list-users__sended">
                        {usersNames}
                    </ul> 

                    <small>Quando foi enviada</small>
                    <h5> {date} </h5>


                </div>
                <div className="modal-footer">
                </div>
            </div>
       );
    }
}

export default SmeMessageDetails  