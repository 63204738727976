import React from "react"

class TimeDisplay extends React.Component {

    render() {

        let value = this.props.value;
        let hours = parseInt(value);

        let n = Math.abs(value);
        let decimal = n - Math.floor(n);

        let minutes = parseInt(decimal * 60);

        if(minutes < 10) {
            minutes = "0" + minutes;
        }

        return (

            <span className="time-display-component">{hours}h:{minutes}min</span>

        );
    }
}

export default TimeDisplay;
