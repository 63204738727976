import React from "react"
import {Line} from "react-chartjs-2"
import LoadingOverlay from "react-loading-overlay";

class ChartMessagesSentPerDay extends React.Component {

    constructor(props) {
        super(props);
        let now = new Date();
        this.state = {
            mes: now.getMonth(),
            ano: now.getYear() + 1900
        };
    };

    render() {

        let xAxisQtdAlerts = [];
        let yAxisQtdAlerts = [];
        let countAlerts = 0;

        this.props.data.forEach(sensor => {
            xAxisQtdAlerts.push(moment.utc(sensor.dia).format('DD/MM/YYYY')),
            yAxisQtdAlerts.push(sensor.numero_alertas)
            countAlerts += sensor.numero_alertas;
        });

        let chartData = {
            labels: xAxisQtdAlerts,
            datasets: [{
                label: "Quantidade de alertas enviados por dia",
                data: yAxisQtdAlerts,
                backgroundColor: "#F443360d",
                borderColor: "#F44336",
                pointHoverBackgroundColor: "#F44336"
            }]
        };

        let chartOptions = {
            scales: {
                xAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true
                    },
                    ticks: {
                        display: false
                    }
                }],
                yAxes: [{
                    display: true,
                    scaleLabel: {
                        display: false,
                        labelString: 'Value'
                    }
                }]
            },
            layout: {
                padding: {
                    top: 10,
                    right: 20,
                    bottom: 10,
                    left: 20
                }
            }
        };

        let titleChart = "Quantidade de alertas";
        if (countAlerts == 1) {
            titleChart += " - 1 alerta"
        } else if (countAlerts > 0) {
            titleChart += " - " + countAlerts + " alertas"
        }

        let chart = null;
        if(this.props.data.length) {
            chart = (<Line data={chartData} options={chartOptions} />)
        }

        if (this.props.noData) {
            chart = (<div className="no-data-timeline"><i className="fa fa-check fa-margin-top" style={{fontSize: 3 + 'em', color: "#40BDA7"}}></i><br/><span style={{fontSize: 2 + 'em', color: "#40BDA7"}}>Parabéns!<br/>Você não possui envio de alertas neste mês.</span></div>)
        }

        return (
            <div className="row">
                <h4 className="page-subtitle timeline-page-subtitle">{titleChart}</h4>
                <div className="react-chart-area">
                    <div className="loading-wrapper" style={{display: this.props.isLoading ? 'block' : 'none'}}>
                        <LoadingOverlay
                            active={this.props.isLoading}
                            spinner
                            color="#40BDA7"
                            text='Carregando dados de alertas...'/>
                    </div>
                    {chart}
                </div>
            </div>
        );
    }
}

export default ChartMessagesSentPerDay
