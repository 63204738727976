import React from "react";
import AnaliseTemporalFilter from "./AnaliseTemporalFilter";
import AnaliseTemporalSelectWeek from "./AnaliseTemporalSelectWeek";
import AnaliseTemporalSelectedWeekList from "./AnaliseTemporalSelectedWeekList";
import AnaliseTemporalChartDygraph from "./AnaliseTemporalChartDygraph";
import AnaliseTemporalTable from "./AnaliseTemporalTable";

class AnaliseTemporalPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filterParams: {
                selectedCompanyId: null,
                selectedSensorId: null,
                dateInicio: null,
                dateFim: null
            },
            selectedWeeks: [0],
            availableWeeks: this.generateAvailableWeeks(),
            tableData: []
        };
    };

    onChangeFilterParams = (filterParams) => {
        this.setState({filterParams: filterParams});
    };

    onWeekAdded = (week) => {
        let currentSelectedWeeks = this.state.selectedWeeks;
        let currentAvailableWeeks = this.state.availableWeeks;
        let newAvailableWeeks = currentAvailableWeeks.filter(option => {
            return option != (parseInt(week))
        });

        this.setState({
            selectedWeeks: [...currentSelectedWeeks, parseInt(week)],
            availableWeeks: newAvailableWeeks.sort((a,b) => parseInt(a) - parseInt(b))
        });
    };

    onWeekRemoved = (week) => {
        let currentSelectedWeeks = this.state.selectedWeeks;
        let currentAvailableWeeks = this.state.availableWeeks;

        let newSelectedWeeks = currentSelectedWeeks.filter(selectedWeek => {
            return selectedWeek !== week;
        });

        let newAvailableWeeks = [...currentAvailableWeeks, (parseInt(week))];

        this.setState({
            selectedWeeks: newSelectedWeeks,
            availableWeeks: newAvailableWeeks.sort((a,b) => parseInt(a) - parseInt(b))
        });
    };

    generateAvailableWeeks = () => {
        let maxWeeks = 48;
        let weeks = [];
        for (let i = 1; i <= maxWeeks; i++) {
            weeks.push(i)
        }
        return weeks
    };

    render() {

        let showWeekSelection = this.state.filterParams.dateInicio
            && this.state.filterParams.dateFim
            && this.state.selectedWeeks.length < 5

        return (
            <div className="analise-temporal-page">

                <AnaliseTemporalFilter
                    token={this.props.token}
                    userId={this.props.userId}
                    onChangeFilterParams={this.onChangeFilterParams}
                    translate={this.props.translate}
                />

                <AnaliseTemporalSelectedWeekList
                    filterParams={this.state.filterParams}
                    onWeekRemoved={this.onWeekRemoved}
                    selectedWeeks={this.state.selectedWeeks}
                    translate={this.props.translate}
                    />

                {showWeekSelection ? (<AnaliseTemporalSelectWeek
                    onWeekAdded={this.onWeekAdded}
                    filterParams={this.state.filterParams}
                    selectedWeeks={this.state.selectedWeeks}
                    availableWeeks={this.state.availableWeeks}
                    translate={this.props.translate}/>) : null}

                <AnaliseTemporalChartDygraph
                    selectedWeeks={this.state.selectedWeeks}
                    filterParams={this.state.filterParams}
                    maxTemperature={this.state.filterParams.maxTemperature}
                    minTemperature={this.state.filterParams.minTemperature}
                    token={this.props.token}
                    translate={this.props.translate}/>

                <AnaliseTemporalTable
                    selectedWeeks={this.state.selectedWeeks}
                    filterParams={this.state.filterParams}
                    token={this.props.token}
                    translate={this.props.translate}/>
            </div>
        );
    }

}

export default AnaliseTemporalPage
