import axios from "axios";

const CancelUserCompanies = axios.CancelToken;
var cancelUserCompanies;
const getUserCompanies = function (user_id, token) {
    cancelUserCompanies && cancelUserCompanies();

    return axios.get('/api/v2/users/' + user_id + '/companies',
        {
            headers: {
                "Authorization": token
            },
            cancelToken: new CancelUserCompanies(
                function executor(c) {
                    cancelUserCompanies = c;
                }
            )
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

export {getUserCompanies}