import React from "react"
import LoadingOverlay from "react-loading-overlay";

class DesempenhoTransporteDados extends React.Component {

    render() {

        let tempoAbaixoPadrao = null;
        let resto_minutos = 0
        let hora = 0

        if(this.props.data) {

            resto_minutos = this.props.data.total_minutes_violated % 60;
            hora = parseInt(this.props.data.total_minutes_violated / 60)

            if(resto_minutos < 10) {
                tempoAbaixoPadrao = hora + ":0" + resto_minutos + ":00"
            } else {
                tempoAbaixoPadrao = hora + ":" + resto_minutos + ":00"
            }

        }

        return (

            <div className="col m6 s12 desempenho-fixos-container">
                <h4 className="page-subtitle">Custo geral de funcionamento abaixo do padrão</h4>
                <div className="desempenho-data-area">

                    <div className="loading-wrapper" style={{display: this.props.isLoading ? 'block' : 'none'}}>
                        <LoadingOverlay
                            active={this.props.isLoading}
                            spinner
                            color="#40BDA7"
                            text='Carregando dados...'/>
                    </div>

                    {this.props.data ? (
                        <div className="container pull-left text-center">
                            <p>Tempo funcionando abaixo do padrão:</p>
                            <h1>{tempoAbaixoPadrao}</h1>
                            <p>Valor desperdiçado por trabalhos abaixo do padrão:</p>
                            {hora + resto_minutos == 0 ? (<h1 className="money-value-ok">{formatMoney(this.props.data.wasted_money)}</h1>) : (<h1 className="money-value">{formatMoney(this.props.data.wasted_money)}</h1>)}
                            
                        </div>

                    ) : null}

                </div>

            </div>

        );
    }
}

function formatMoney(num) {
    var p = num.toFixed(2).split(".");
    return "R$ " + p[0].split("").reverse().reduce(function(acc, num, i, orig) {
        return  num=="-" ? acc : num + (i && !(i % 3) ? "." : "") + acc;
    }, "") + "," + p[1];
}

export default DesempenhoTransporteDados
