import React from "react"

class BtnRelatorios extends React.Component {

    render() {

        let opcaoFixos = null;
        let colNumber = 6;
        if(this.props.hasFixos) {
            colNumber = 4;
            opcaoFixos = (
                <div className="col s12">
                    <button className="btn-download-desempenho btn modal-trigger" 
                        data-href={"/pdf/general_view_percentage/" + this.props.month + "/" + this.props.year + "/" + this.props.company}
                        data-target="modal-esignature-optional"
                        data-operation-code="12">
                        <span className="fa fa-download"></span> {this.props.translate.humidity_and_temperature}
                    </button>
                </div>
            )
        }

        return (
            <div className="relatorio-buttons-wrapper col s12 center-align">
                <div className="left">

                    <h5 className="center-align">{this.props.translate.monthly_reports_text}</h5>

                    <div className="row">
                        <div className="col s12">
                            <button className="btn-download-desempenho btn modal-trigger" 
                                data-href={"/pdf/indicadores_desempenho/" + this.props.month + "/" + this.props.year + "/" + this.props.company}
                                data-target="modal-esignature-optional"
                                data-operation-code="4">
                                <span className="fa fa-download"></span>{this.props.translate.performance_indicators}
                            </button>
                        </div>

                        {opcaoFixos}
                        
                        <div className="col s12">
                            <button className="btn-download-desempenho btn modal-trigger" 
                                data-href={"/pdf/evolucao_semanal/" + this.props.month + "/" + this.props.year + "/" + this.props.company} 
                                data-target="modal-esignature-optional"
                                data-operation-code="2">
                                <span className="fa fa-download"></span>{this.props.translate.weekly_evolution}
                            </button>
                        </div>

                        <div className="col s12">
                            <button className="btn-download-desempenho btn modal-trigger" 
                                data-href={"/pdf/report-waste-energy/" + this.props.month + "/" + this.props.year + "/" + this.props.company}
                                data-target="modal-esignature-optional"
                                data-operation-code="4">
                                <span className="fa fa-download"></span>{this.props.translate.energy}
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default BtnRelatorios
