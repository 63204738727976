import React from "react"
import LoadingOverlay from "react-loading-overlay";
import {Bar} from "react-chartjs-2";

class DesempenhoTransporteChart extends React.Component {

    render() {

        let borderColors = ["#F44336", "#4bc0c0", "#36a2eb"];
        let backgroundColors = ["#F4433670", "#4bc0c07a", "#36a2eb73"];
        let count = 0;
        let chartData = {
            datasets: []
        };

        if (this.props.data) {

            this.props.data.worst_groups.forEach(group => {
                chartData.datasets.push({
                    label: group.group_label,
                    backgroundColor: backgroundColors[count],
                    borderColor: borderColors[count],
                    hoverBackgroundColor: borderColors[count],
                    borderWidth: 3,
                    data: [group.minutes_violated / 60]
                });
                count++;
            });

        }

        let chartOptions = {
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        let valorY = tooltipItem.yLabel
                        let minutosTotal = Math.ceil(valorY * 60);
                        let horas = parseInt(minutosTotal / 60);
                        let minutos = minutosTotal % 60;
                        if (minutos < 10) {
                            minutos = "0" + minutos;
                        }
                        return " " + horas + "h" + minutos + "min";
                    }
                }
            },
            legend: {
                position: 'top',
            },
            layout: {
                padding: {
                    top: 10,
                    right: 20,
                    bottom: 0,
                    left: 20
                }
            },
            scales: {
                yAxes: [{
                    display: true,
                    ticks: {
                        maxTicksLimit: 6,
                        beginAtZero: true
                    }
                }]
            }
        };

        let chart = null;
        
        if (this.props.data && this.props.data.worst_groups.length) {
            chart = (<Bar data={chartData} options={chartOptions}/>)
        }

        if (this.props.data && this.props.data.worst_groups.length === 0) {
            chart = chart = (<div className="no-data-timeline"><i className="fa fa-check fa-margin-top" style={{fontSize: 3 + 'em', color: "#40BDA7"}}></i><br/><span style={{fontSize: 2 + 'em', color: "#40BDA7"}}>Parabéns!<br/>Você não possui desperdício de diesel neste mês</span></div>)
        }

        return (

            <div className="col m6 s12 desempenho-fixos-container">
                <h4 className="page-subtitle timeline-page-subtitle">Grupos com maior tempo abaixo do padrão</h4>
                <div className="desempenho-data-area">
                    <div className="loading-wrapper" style={{display: this.props.isLoading ? 'block' : 'none'}}>
                        <LoadingOverlay
                            active={this.props.isLoading}
                            spinner
                            color="#40BDA7"
                            text='Carregando dados...'/>
                    </div>
                    {chart}
                </div>
            </div>

        );
    }
}

export default DesempenhoTransporteChart
