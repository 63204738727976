import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import LoadingOverlay from "react-loading-overlay";

const ChangeStatusSensor = (props) => {
	const [company, setCompany] = useState('');
	const [status, setStatus] = useState('');
	const [sensors, setSensors] = useState('');
	const [error, setError] = useState('');
	const [showOtherInputs, setshowOtherInputs] = useState(false);
	const [justification, setJustification] = useState('');
	const [statusToChange, setStatusToChange] = useState('');
	const [sensorsToSend, setSensorsToSend] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		let selectCompany = document.getElementById('companies-select');
		let selectStatus = document.getElementById('status-select');
		let selectStatusToChange = document.getElementById('statusToChange-select');
		M.FormSelect.init(selectCompany);
		M.FormSelect.init(selectStatus);
		M.FormSelect.init(selectStatusToChange);
	});

	const handleCompany = (event) => {
		setCompany(event.target.value)
	}

	const handleStatus = (event) => {
		setStatus(event.target.value);
		setSensors('');
	}

	const handleJustification = (event) => {
		setJustification(event.target.value)
	}

	const handleStatusToChange = (event) => {
		setStatusToChange(event.target.value)
	}

	const handleSensorsToSend = (event) => {
		if(sensorsToSend.includes(event.target.value)){
			var index = sensorsToSend.indexOf(event.target.value)
			var newState = []
			if ( index > -1) {
				newState = sensorsToSend.splice(index, 1);
			}
			setSensorsToSend(sensorsToSend)
		}else{
			setSensorsToSend([...sensorsToSend, event.target.value]);
		}
	}

	const handleShowOtherInputs = () => {
		setshowOtherInputs(true)
	}

	const getSensors = () => {
		setIsLoading(true);
		let url_to_request = '/api/v2/change_all_sensor_status?company_id=' + company + '&status=' + status
		axios.get(url_to_request,
		{
			headers: {
				"Authorization": props.token
			}
		})
		.then(response => {
			setIsLoading(false);
			if(!response.data.success){
				setError(response.data.message)
				setSensors('')
				// setshowOtherInputs(false)
			}else{
				setError('')
				setSensors(response.data.all_sensors)
			}
		})
		.catch(error => {
			return Promise.reject(error);
		});
	}

	const handleChangeStatus = () => {
		setIsLoading(true);
		axios.post('/api/v2/change_all_sensor_status', {
			headers: {
				"Authorization": props.token
			},
			params: {
				ids: sensorsToSend,
				status: statusToChange,
				justification: justification,
				user_id: props.user_id
			}
		}).then(response => {
			if(!response.data.success){
				M.toast({html: response.data.message})

			}else{
				// setSensors('')
				setshowOtherInputs(false);
				getSensors()
			}
			setIsLoading(false);
		})
		.catch(err => console.warn(err));
	}

	return (
		<div className="row">
			<select id={'companies-select'} className={'input-field'} onChange={handleCompany}>
				{
					props.companies.map((item, index) => (
						<option key={item.id} value={item.id}>{item.name}</option>
					))
				}
			</select>

			<select id={'status-select'} className={'input-field'} onChange={handleStatus}>
				{
					props.all_status_hardware.map((item, index) => (
						<option key={item.id} value={item.id}>{item.name}</option>
					))
				}
			</select>

			<div>
				<span style={{marginBottom: 20, marginTop: 20, display: "block"}}>{error ? error : ''}</span>
			</div>

			<button type={'button'} className='btn left col m12' onClick={getSensors}> 
				Filtrar
			</button>

			<div style={{marginTop: 70, display: 'block'}}>
				<LoadingOverlay
					active={isLoading}
					spinner
					color="#40BDA7"
					text='Carregando dados...'
				/>

				{
					sensors
						?
							<>
								<h2>Lista de sensores</h2>
								{
									sensors.map((sensor) => (
										<label key={sensor.id} style={{display: 'block', marginTop: 20, marginBottom: 20}}>
											<input type="checkbox" value={sensor.id} onChange={handleSensorsToSend} onClick={handleShowOtherInputs}/>
											<span>{sensor.label}</span>
										</label>
									))	
								}
							</>
						:
							null
				}

				{
					showOtherInputs
						?
							<div>
								<div>
									<label>Justificativa de mudança de status</label>
									<input type={'text'} className={'input-field'} onChange={handleJustification}></input>
								</div>

								<select id={'statusToChange-select'} className={'input-field'} onChange={handleStatusToChange}>
									{
										props.all_status_hardware.map((item, index) => (
											<option key={item.id} value={item.id}>{item.name}</option>
										))
									}
								</select>
							</div>
						: 
							null
				}

				{
					sensors 
						?
							<>
								<button
									type="button"
									className='btn left col m12'
									onClick={handleChangeStatus}>
									Mudar status
								</button>								
							</>
						:
							null
				}


			</div>
		</div>
  );
}

export default ChangeStatusSensor;