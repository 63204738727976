import React from 'react';

class MapVehicleMarker extends React.Component {

    imageForVehicle = (vehicle) => {
        let connected = vehicle.last_info.connected;
        let last_temperature = vehicle.last_info.temperature;
        let max = vehicle.last_info.temperature_max;
        let min = vehicle.last_info.temperature_min;

        if (!connected) {
            return '/images/truck_gray.png'
        } else if (last_temperature > max) {
            return '/images/truck_red.png'
        } else if (last_temperature < min) {
            return '/images/truck_blue.png'
        } else {
            return '/images/truck_green.png'
        }
    };

    classForVehicle = (vehicle) => {
        let connected = vehicle.last_info.connected;
        let last_temperature = vehicle.last_info.temperature;
        let max = vehicle.last_info.temperature_max;
        let min = vehicle.last_info.temperature_min;

        if (!connected) {
            return 'off-signal'
        } else if (last_temperature > max) {
            return 'above-temperature'
        } else if (last_temperature < min) {
            return 'below-temperature'
        } else {
            return 'normal-temperature'
        }
    };

    render() {

        let sensorsData = []
        this.props.vehicle.sensors.map(sensor => {
            sensorsData.push(<div key={sensor.id}>{sensor.sensor} {sensor.temperature} °C</div>)
        });

        let hiddenContent = (
            <div className={'vehicle-marker-hidden-content ' + this.classForVehicle(this.props.vehicle)}>
                <b>{this.props.vehicle.label}</b>
                <div><i>{this.props.vehicle.company_name}</i></div>
                {sensorsData}

                {this.props.vehicle.last_info.speed ? (
                    <div>Velocidade {this.props.vehicle.last_info.speed} Km/h</div>
                ) : null}

                <div>Dados de {this.props.vehicle.last_info.date_hour_br}</div>
                <div className="vehicle-details">
                    <a target="_blank" href={"/medicoes?id=" + this.props.vehicle.last_info.serial}
                       className={"btn btn-primary"}>Detalhes</a>
                </div>
            </div>);

        return (
            <div className='vehicle-marker'>
                <img src={this.imageForVehicle(this.props.vehicle)} width={48} height={48}/>
                {hiddenContent}
            </div>
        );
    }
}

export default MapVehicleMarker
