import React from "react"
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";

class GenerateToken extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            statusRequest: false,
            users: [],
            companies: [],
            loading: true,
            token: ''
        }
    }

    initializeSelect = () => {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems);  
    }

    componentDidMount(){
        this.gettingUsersAndCompanies()
    }

    gettingUsersAndCompanies = () => {
        var url_to_request = '/api/v2/sme_new_message_select';
        return axios.get(url_to_request,
            {
                headers: {
                    "Authorization": this.props.token
                }
            })
            .then(response => {
                if(response.status == 200){
                    var companiesOptions = response.data.companies.map((item, index) => (
                        <option key={'comp-'+item.id} value={'comp-'+item.id}>{item.name}</option>
                    ))   

                    var usersOptions = response.data.users.map((item, index) => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                    ))   
                    
                    this.setState({companies: companiesOptions}); 
                    this.setState({users: usersOptions}); 
                    this.setState({statusRequest: true});
                    this.setState({loading: false});

                    this.initializeSelect()
                }
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }

    generateToken = () => {
        var companyID = document.getElementById('companies-select').value
        var userID = document.getElementById('users-select').value

        var url_to_request = '/api/v2/generate_token?company=' + companyID + '&user=' + userID;
        return axios.get(url_to_request,
            {
                headers: {
                    "Authorization": this.props.token
                }
            })
            .then(response => {
                if(response.status == 200){
                    this.setState({token: response.data.token})
                }
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }

    render(){

        if(this.state.statusRequest == true){
            this.initializeSelect()
        }


        return(
            <div className="row generate-token">
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    color="#40BDA7"
                    text='Carregando dados'/>

                { this.state.statusRequest == true ?  
                    <div className="usersAndCompanies col m5">
                        <div className="companies-select col m12" alt='Select de empresas'>
                            <label htmlFor='companies-selec'>Empresas</label>
                            <select id="companies-select">
                                {this.state.companies}
                            </select>
                        </div>

                        <div className="users-select col m12" alt='Select de usuários'>
                            <label htmlFor='users-select'>Usuários</label>
                            <select id="users-select">
                                {this.state.users}
                            </select>
                        </div>

                        <a className="btn left" onClick={() => this.generateToken()}>Gerar Token</a>
                    </div>
                                                
                    : null 
                }   

                { this.state.token != '' ? 
                    <div className="col m12">
                        <input disabled id="valueToCopy" className="col m12" value={this.state.token}></input>
                        <button type="button" className="btn left" onClick={() => {navigator.clipboard.writeText(this.state.token)}}>Copy to clipboard</button>
                    </div>

                    : null }


            </div>
        );
    }
}

export default GenerateToken
