import React from "react"

class Input extends React.Component {
    constructor(props){
        super(props)
    }

    render() {
        if(this.props.id.length > 0 ){
            var id = this.props.id
        }else{
            var id = 'input-' + this.props.type
        }

        if(this.props.type > 0){
            var type = this.props.type
        }else{
            var type = 'text'
        }
        
        if(this.props.classes.length > 0) {
            var classes = this.props.classes
        }else{
            var classes = 'input-field col s5 m4'
        }

        if(this.props.label){
            var label = this.props.label;
            var placeholder = '';
        }

        return(
            <div className={classes} alt={this.props.alt}>
                <label htmlFor={id}> {label} </label>
                <input type={type} id={id} ></input>
            </div>
        );
    }
}

export default Input  