import React from "react"
import LoadingOverlay from "react-loading-overlay";
import {Bar} from "react-chartjs-2";
import TimeDisplay from "../shared/TimeDisplay"
import MoneyDisplay from "../shared/MoneyDisplay"
import BtnRelatorios from "./BtnRelatorios";

class AmbienteSidebar extends React.Component {

    render() {

        let desperdicio = null;
        let chart = null;
        let keyword = this.props.isEnergy ? "ambientes" : "baús de caminhão";

        if (this.props.desperdicioData) {
            desperdicio = (
                <div className="desperdicio-data">
                    <p className="title-total">{this.props.translate.total_wasted}</p>
                    <p className="total-time"><TimeDisplay value={this.props.desperdicioData.total_time}/></p>
                    <p className="total-cost"><MoneyDisplay value={this.props.desperdicioData.total_cost}/></p>
                </div>
            )

            if (this.props.desperdicioData.total_cost > 0 && this.props.desperdicioData.worst_ambientes.length > 0) {


                let backgroundColors = ["#DD2C0070", "#FF572270", "#FFAB0070", "#FFD60070", "#FFEA0070"];
                let borderColors = ["#DD2C00", "#FF5722", "#FFAB00", "#FFD600", "#FFEA00"];
                let count = 0;
                let chartData = {
                    datasets: []
                };

                this.props.desperdicioData.worst_ambientes.forEach(ambiente => {

                    chartData.datasets.push({
                        label: ambiente.name,
                        backgroundColor: backgroundColors[count],
                        borderColor: borderColors[count],
                        hoverBackgroundColor: borderColors[count],
                        borderWidth: 3,
                        data: [ambiente.total_cost]
                    });
                    count++;
                });

                let chartOptions = {
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItem, data) {

                                let label = tooltipItem.yLabel.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});

                                return label + " desperdiçados";
                            }
                        }
                    },
                    legend: {
                        position: 'top',
                    },
                    layout: {
                        padding: {
                            top: 10,
                            right: 20,
                            bottom: 0,
                            left: 20
                        }
                    },
                    maintainAspectRatio: false,
                    scales: {
                        yAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                };

                chart = (
                    <div>
                        <div className="react-chart-area">
                            <Bar data={chartData} options={chartOptions}/>
                        </div>
                        <div className="col s12 center-align">Gráfico com os piores {keyword}</div>
                    </div>)
            }
        }

        return (
            <div className="right col s12 m5 desperdicio-wrapper">

                <div className={"desperdicio-container " + (this.props.isLoading ? "" : "loaded")}>

                    <div className="loading-wrapper" style={{display: this.props.isLoading ? 'block' : 'none'}}>
                        <LoadingOverlay
                            active={this.props.isLoading}
                            spinner
                            color="#40BDA7"
                            text='Carregando dados de desperdício...'/>
                    </div>
                    {desperdicio}
                    {chart}

                </div>

                {!this.props.currentUserIsOperator ? (
                    <BtnRelatorios month={this.props.month} year={this.props.year} company={this.props.company} hasFixos={this.props.has_fixos} translate={this.props.translate} />
                ) : null}

            </div>
        );
    }
}

export default AmbienteSidebar
