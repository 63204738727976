import axios from "axios";

const CancelCompanySensors = axios.CancelToken;
var cancelCompanySensors;
const getCompanySensors = function (company_id, token) {
    cancelCompanySensors && cancelCompanySensors();

    return axios.get('/api/v2/companies/' + company_id + '/sensors',
        {
            headers: {
                "Authorization": token
            },
            cancelToken: new CancelCompanySensors(
                function executor(c) {
                    cancelCompanySensors = c;
                }
            )
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(error);
        });

};

export {getCompanySensors}