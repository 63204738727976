import React from "react"
import axios from 'axios';
import SensorCard from "../newly_sensors/SensorCard";

class NewlySensors extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            sensorCards: [],
        }
    }

    generateSensorCards = (sensors) => {
        let sensorCards = [];

        sensors.forEach(function(sensor){
            sensorCards.push(<SensorCard key={sensor.sensor_id} infoSensor={sensor}></SensorCard>)
        });

        this.setState({sensorCards: sensorCards});
    }

    gettingSensors = () => {
        var url_to_request = '/api/v2/newly_sensors';
        return axios.get(url_to_request,
                {
                    headers: {
                        "Authorization": this.props.token
                    }
                })
                .then(response => {
                    this.generateSensorCards(response.data.sensors)
                })
                .catch(error => {
                    return Promise.reject(error);
                });
    }

    componentDidMount(){
        this.gettingSensors()
    }

    render(){
        return(
            <>
                <h2>Alertas de sensores recentes</h2>
                <div className="divider"></div>
                <div className="row newlyContainer">
                    { this.state.sensorCards ? this.state.sensorCards : null}
                </div>
            </>
        );
    }
}

export default NewlySensors
