import React from "react"
import LoadingOverlay from "react-loading-overlay";
import axios from 'axios';
import SmeMessageDetails from '../sme_messages/SmeMessageDetails';

class SmeMessageItem extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            messageDetails: []
        }
    }

    colorBadge = (messageType) => {
        if(messageType == 'E-mail'){
            return 'new badge email'
        }else if(messageType == 'App'){
            return 'new badge app'
        }
    }

    getMessageDetails = (messageID) => {
        var url_to_request = '/api/v2/sme_message_logs/' + messageID;
        return axios.get(url_to_request,
                {
                    headers: {
                        "Authorization": this.props.token
                    }
                })
                .then(response => {
                    this.setState({messageDetails: response.data})

                })
                .catch(error => {
                    return Promise.reject(error);
                });

    }

    render() {
        
        if(this.props.messageInfo){
            var messageID = this.props.messageInfo.id;
            var classes = 'message_item message-item__' + messageID + ' ';

            var messageData = this.props.messageInfo.created_at;
            messageData = messageData.split('-');
            messageData = messageData[2].slice(0, 2) + '/' + messageData[1] + '/' + messageData[0];

            if(this.props.messageInfo.type_messages == 1){
                var messageType = ['E-mail'];
            }else if(this.props.messageInfo.type_messages == 2){
                var messageType = ['App'];
            }else {
                var messageType = ['E-mail', 'App'];
            }
        }

        return(
            <li className={classes} id={messageID}>
                <div className="collapsible-header">
                    <div className="message-item__title col m9">
                        <i className="fas fa-envelope"></i>
                        {this.props.messageInfo.title}
                    </div>
                    <div className="message-item__type col m2 right-align">
                        {messageType.length == 1 
                            ? <span className={this.colorBadge(messageType)}> {messageType[0].toString()} </span>
                            : <div>
                                <span className={this.colorBadge(messageType[0])}>{messageType[0]}</span>
                                <span className={this.colorBadge(messageType[1])}>{messageType[1]}</span>
                              </div>}
                    </div>
                    <div className="message-item__data col m1 right-align">
                        <b>{messageData}</b>
                    </div>
                </div>

                <div className="collapsible-body">
                    <div className="message-item__user left left-align">
                        <h2>{this.props.messageInfo.sender_name}</h2>
                    </div>
                    <div className="message-item__company right-align">
                        <h2>{this.props.messageInfo.company_name}</h2>
                    </div>
                    <div className="message-item__body">
                        <p>{this.props.messageInfo.body}</p>
                    </div>
                    <hr></hr>
                    <a className="message-log waves-effect waves-light btn modal-trigger" href={'#modal-' + messageID} onClick={() => this.getMessageDetails(messageID)}>Ver detalhes da mensagem</a>

                    <SmeMessageDetails 
                        messageDetails={this.state.messageDetails}
                        classes={'modal bottom-sheet'}
                        id={'modal-' + messageID}
                    />
                </div>
            </li>
        );
    }
}

export default SmeMessageItem