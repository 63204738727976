import React from "react"
import DateFilter from "../DateFilter";

class MeasurementsMonthlyReports extends React.Component {

    constructor(props) {
        super(props);
        let now = new Date();
        this.state = {
            month: now.getMonth() + 1,
            year: now.getYear() + 1900,
            includeIncidents: true
            // showOff: true
        };
    };

    handleChangeIncludeIncidents = () => {
        this.setState({
            includeIncidents: !this.state.includeIncidents
        });
    };

    onChangeDateHandler = (newMonth, newYear) => {
        this.setState({
            month: newMonth,
            year: newYear
        })
    };

    onClickTemperatureReport = () => {
        let reportName = event.target.dataset.reportName

        swal({
            title: 'Justificativas',
            text: "Deseja colocar um espaço para justificar qualquer possível violação no relatório?",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Não',
            cancelButtonColor: '#fff',
            cancelButtonText: 'Sim',
            customClass: 'custom-styled'
        }).then((result) => {
            this.openEsignatureForTemperatureMonthlyReport(0, reportName);
        }).catch((result => {
            if(result == 'cancel') {
                this.openEsignatureForTemperatureMonthlyReport(1, reportName);
            }
        }));

    };

    openEsignatureForTemperatureMonthlyReport = (justificativas, reportName) => {
        // Isso aqui usa variáveis globais e funções jQuery
        global_materialize_modal_params.operation_code = 7;
        global_materialize_modal_params.related_class = 'sensor';
        global_materialize_modal_params.related_id = this.props.sensor_id;
        global_materialize_modal_params.function_to_call = this.onClickTemperatureReport;
        // Aqui é usado uma função que está em um arquivo JS global da página, not so pretty but it works
        let url = addParamInUrl(`/pdf/${reportName}/` + this.props.sensor_id + "/" + this.state.month + "/" + this.state.year + "?include_incidents="+ this.state.includeIncidents +"&justificativas=" + justificativas + "&token=" + this.props.token,'signed', global_materialize_modal_params.already_signed);
        global_materialize_modal_params.href = url
        $('#modal-esignature-optional').modal('open');
    }

    render() {

        let classForContainer = "m4";
        let classForButtons = "m12";
        if (!this.props.view_map) {
            classForContainer = "m6";

            if (this.props.has_humidity) {
                classForButtons = "xl6"
            }
        }

        return (
        <div className={"col s12 " + classForContainer + " measurements-monthly-reports-wrapper"}>
            <h4>{this.props.translate.monthly_reports_text}</h4>
            <div className="report-wrapper full-width">
                <DateFilter onChangeDateHandler={this.onChangeDateHandler}/>
                <div className="report-buttons-container">

                    <div className="show-companies-container">
                        <div className="pretty p-switch">
                            <input className="include-incidents-checkbox" type="checkbox" id="include-incidents" name="include-incidents" onChange={this.handleChangeIncludeIncidents} checked={this.state.includeIncidents}/>
                            <div className="state p-success">
                                <label htmlFor="include-incidents">{this.props.translate.include_incidents}</label>
                            </div>
                        </div>
                    </div>
                    <div className={"col s12 m12 l12 " + classForButtons}>
                        <button className={"custom-btn custom-btn-success full-width"} target="_blank" data-report-name="report_month" onClick={this.onClickTemperatureReport}>
                            <span className="fa fa-download"></span>{this.props.translate.monthly_temperature_report_text}
                        </button>
                    </div>
                    {this.props.has_humidity ? (
                        <div className={"col s12 m12 l12 " + classForButtons}>
                            <button className={"custom-btn custom-btn-success full-width"} target="_blank" data-report-name="humidity_report" onClick={this.onClickTemperatureReport}>
                                <span className="fa fa-download"></span>{this.props.translate.monthly_humidity_report_text}
                            </button>
                        </div>
                    ) : null}
                    {this.props.has_humidity ? (
                        <div className={"col s12 m12 l12 " + classForButtons}>
                            <button className={"custom-btn custom-btn-success full-width"} target="_blank" data-report-name="temperature_humidity_report" onClick={this.onClickTemperatureReport}> 
                                <span className="fa fa-download"></span>{this.props.translate.monthly_temperature_humidity_report_text}
                            </button>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
        );
    }
}

export default MeasurementsMonthlyReports
