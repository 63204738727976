import React from "react"
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import {Line} from "react-chartjs-2"
import * as ChartAnnotation from 'chartjs-plugin-annotation';

class Predictor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            response: null,
            isLoading: true
        };
    };

    requestPrediction = () => {

        axios.post('https://packid-ml-flask.azurewebsites.net/predict',
        {
            sensor_id: this.props.sensor_id,
            token: this.props.token
        })
        .then(response => {
            console.log(response)
            this.setState({
                response: response,
                isLoading: false
            });
        })
        .catch(error => {
            console.log("Error requesting prediction:", error)
            this.setState({
                isLoading: false
            });
        });

    }

    componentDidMount() {
        this.requestPrediction();
    }

    render() {

        let chart = null;

        if (this.state.response && this.state.response.data) {
            let xAxisDateHour = [];
            let yAxisTemperaturePast = [];
            let yAxisTemperatureFuture = [];

            console.log('Temperaturas passadas', this.props.predictors_chart)              
            let previousTime = null;
            let meanTimeBetween = 0;
            this.props.predictors_chart.forEach(pastTemperature => {
                let currentTime = moment.utc(pastTemperature.date_hour)
                xAxisDateHour.push(currentTime.format('DD/MM/YYYY HH:mm'))
                if(previousTime){
                    meanTimeBetween += currentTime.diff(previousTime)
                }
                previousTime = currentTime;
                yAxisTemperaturePast.push(pastTemperature.temperature)
                yAxisTemperatureFuture.push(null)
            });

            console.log("meanTimeBetween", meanTimeBetween)

            let minutesBetween = Math.ceil(((meanTimeBetween / this.props.predictors_chart.length) / 1000) / 60)

            let predictions = this.state.response.data.future
            console.log('Temperaturas futuras', predictions)      
            predictions.forEach(futureTemperature => {
                let currentTime = previousTime.add(minutesBetween, 'minutes');
                xAxisDateHour.push(currentTime.format('DD/MM/YYYY HH:mm'))
                previousTime = currentTime;
                yAxisTemperaturePast.push(null)
                yAxisTemperatureFuture.push(futureTemperature)
            });

            console.log('xAxisDateHour', xAxisDateHour)

            let chartData = {
                labels: xAxisDateHour,
                datasets: [
                    {
                        label: "Últimas 100 leituras",
                        data: yAxisTemperaturePast,
                        borderColor: "#FF5722",
                        fill: false,
                        pointBackgroundColor: "#FF5722",
                        pointHoverBackgroundColor: "#FF5722"
                    },
                    {
                        label: "Temperaturas previstas",
                        data: yAxisTemperatureFuture,
                        borderColor: "#40BDA7",
                        fill: false,
                        pointBackgroundColor: "#40BDA7",
                        pointHoverBackgroundColor: "#40BDA7"
                    }
                ]
            };

            let chartOptions = {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [{
                        display: true,
                        scaleLabel: {
                            display: true
                        },
                        ticks: {
                            display: false
                        },
                        gridLines: {
                            display:false
                        }
                    }],
                    yAxes: [{
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: 'Value'
                        }
                    }]
                },
                layout: {
                    padding: {
                        top: 10,
                        right: 20,
                        bottom: 10,
                        left: 20
                    }
                },
                annotation: {
                    annotations: [
                        {
                            type: 'line',
                            mode: 'horizontal',
                            scaleID: 'y-axis-0',
                            value: this.props.temperature_min,
                            borderColor: '#73bdf1',
                            borderWidth: 6
                        },
                        {
                            type: 'line',
                            mode: 'horizontal',
                            scaleID: 'y-axis-0',
                            value: this.props.temperature_max,
                            borderColor: '#ff91a8',
                            borderWidth: 4
                        }
                    ]
                }
            };

            chart = (<div style={{height: 400}}><Line data={chartData} options={chartOptions} plugins={[ChartAnnotation]} /></div>)
        }





        return (
        <div className={"col s12"}>
            <h4>Predição de temperatura [ALPHA PREVIEW]</h4>
            <div className="report-wrapper full-width" style={{minHeight: '200px', maxHeight: '400px'}}>
            
                <div className="loading-wrapper" style={{display: this.state.isLoading ? 'block' : 'none'}}>
                    <LoadingOverlay
                        active={this.state.isLoading}
                        spinner
                        color="#40BDA7"
                        text='Carregando as predições. Isto pode demorar um pouco.'/>
                </div>

                {chart}
            </div>
        </div>
        );
    }
}

export default Predictor