import axios from "axios";

const CancelTokenCompanies = axios.CancelToken;
var cancelCompanies;
const fetchCompanies = function (token) {
    cancelCompanies && cancelCompanies()

    return axios.get('/api/v2/map/companies',
        {
            headers: {
                "Authorization": token
            },
            cancelToken: new CancelTokenCompanies(
                function executor(c) {
                    cancelCompanies = c;
                }
            )
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

const CancelTokenLastPositions = axios.CancelToken;
var cancelLastPositions;
const fetchLastPositions = function(company_ids, token) {
    cancelLastPositions && cancelLastPositions()

    return axios.post('/api/v2/map/last-positions',
        {
            company_ids: company_ids
        },
        {
            headers: {
                "Authorization": token
            },
            cancelToken: new CancelTokenLastPositions(
                function executor(c) {
                    cancelLastPositions = c;
                }
            )
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

export {fetchCompanies, fetchLastPositions}