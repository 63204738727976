import React from "react"
import LoadingOverlay from "react-loading-overlay";
import AmbienteListItem from "./AmbienteListItem";

class AmbienteList extends React.Component {

    render() {

        let keyword = this.props.isEnergy ? "ambiente" : "baú de caminhão";
        let keywordPlural = this.props.isEnergy ? "ambientes" : "baús de caminhão";

        let ambientes = (<p className="zero-ambientes-to-show">Nenhum {keyword} disponível para exibição</p>);
        if(this.props.ambientesData.length) {
            ambientes = this.props.ambientesData.map(ambiente => {
                return (
                    <AmbienteListItem key={ambiente.id} ambiente={ambiente} translate={translate} />
                )
            });
        }

        return (
            <div className="left col s12 m7 ambiente-list-wrapper">

                <div className="loading-wrapper" style={{display: this.props.isLoading ? 'block' : 'none'}}>
                    <LoadingOverlay
                        active={this.props.isLoading}
                        spinner
                        color="#40BDA7"
                        text={"Carregando dados dos " + keywordPlural + "..."}/>
                </div>
                {this.props.isLoading ? null : ambientes}

            </div>
        );
    }
}

export default AmbienteList
