import React from "react";
import * as Requests from './SelectSensorRequests'

class SelectSensor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sensors: [],
            selectedSensorId: null
        };
    };

    onChange = (event) => {
        const target = event.target;

        let selectedSensor = this.state.sensors.find(sensor => {
            return sensor.id == target.value
        });

        this.props.onSelectedSensor(selectedSensor);
    };

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.companyId != this.props.companyId) {
            Requests.getCompanySensors(this.props.companyId, this.props.token)
                .then(result => {
                    this.setState({sensors: result.data})
                    if(result.data.length) {
                        this.props.onSelectedSensor(result.data[0]);
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        }

    }

    render() {

        let optionsSensors = this.state.sensors.map(sensor => {
            return (<option key={sensor.id}
                            value={sensor.id}>{sensor.label}</option>)
        });

        return (
            <div className="custom-input-wrapper">
                <div className="custom-input-label">Sensor</div>
                <select className="custom-input"
                        name="selected_sensor_id"
                        onChange={this.onChange}>
                    {optionsSensors}
                </select>
            </div>
        );
    }

}

export default SelectSensor
