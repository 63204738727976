import React from "react"
import LoadingOverlay from "react-loading-overlay";
import SmeMessageItem from '../sme_messages/SmeMessageItem';

class SmeList extends React.Component {
    constructor(props){
        super(props);
    }

    initializeCollapse() {
        var elems = document.querySelectorAll('.collapsible');
        var instances = M.Collapsible.init(elems);
    }

    componentDidMount(){
        this.initializeCollapse()
    }

    clearSearch = () => {
        document.getElementById('sme-itens').innerHTML = ''
    }

    render() {
        if(this.props.messagesData.length > 0) {
            var messages = this.props.messagesData.map(message => {
                return (
                    <SmeMessageItem key={message.id} messageInfo={message} token={this.props.token}/>
                )
            });
        }

        if(this.props.type_stats){
            var email = this.props.type_stats.email_stats
            var app = this.props.type_stats.app_stats
            var all = this.props.type_stats.all_stats
        }

        return(
            <div className="left col s12 m12" style={{marginTop: 30 + 'px'}}>
                <div className="message-type__porcentages">
                    <b>Tipos de mensagem</b>
                    <span className="email-porcentage">{email ? email + '% - E-mail' : null}</span>
                    <span className="app-porcentage">{app ? app + '% - App' : null}</span>
                    <span className="all-porcentage">{all ? all + '% - (E-mail e App)' : null}</span>
                </div>
                <a className="btn waves-effect waves-light red white-text right" id="clear-search" onClick={() => this.clearSearch()}>Limpar busca</a>
                <ul className="collapsible" id="sme-itens">
                    {!messages ? null : messages}
               </ul>
            </div>
        );
    }
}

export default SmeList