import React from "react"
import axios from 'axios'

class MultipleSelect extends React.Component {
    constructor(props){
        super(props)
    }

    initializeSelect = (id) => {
        var elems = document.getElementById(id);
        var instances = M.FormSelect.init(elems);  
    }

    componentDidMount(){
        var id = this.props.id
        this.initializeSelect(id)
    }

    render() {
        if(this.props.id.length > 0 ){
            var id = this.props.id
        }
        
        if(this.props.classes.length > 0) {
            var classes = this.props.classes
        }else{
            var classes = 'input-field col s5 m4'
        }

        if(this.props.label){
            var label = this.props.label;
            var placeholder = '';
        }

        if(this.props.companies){
            var companies = this.props.companies
        }

        if(this.props.users){
            var users = this.props.users
        }

        return(
            <div className={classes} alt={this.props.alt}>
                <select multiple id={id}>
                        {this.props.options}
                </select>
                <label htmlFor={id}> {label} </label>
            </div>
        );
    }
}

export default MultipleSelect  