import React from "react"

class SensorCard extends React.Component {
    constructor(props){
        super(props)
    }

    render() {
        const {
            company_name,
            sensor_label,
            status,
            when_changed,
        } = this.props.infoSensor;

        let whenChangedDate = when_changed.split('.')[0];

        let icon = '';

        if(status == 'Sem instalar'){
            icon = 'power'  
        }else if(status == 'Em transporte'){
            icon = 'airport_shuttle'  
        }else if(status == 'Aguardando ajuste'){
            icon = 'hourglass_bottom'  
        }
        return(
            <div className="col s12 m6 l6 xl4 newlyCard">
                <div className="col s12 m12 newlyCard-shadow">
                    <div className="col s12 m12 newlyCard-company_sensor">
                        <b>{company_name}</b>
                        <p>{sensor_label}</p>
                    </div>
                    <div className="col s12 newlyCard-status_icon">
                        <p><b>{status}</b></p>
                        <span className="material-icons">{icon}</span>
                    </div>
                    <span>Ligado as {whenChangedDate}</span>
                </div>
            </div>
        );
        
    }
}

export default SensorCard  