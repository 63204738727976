import React from "react"
import DateFilter from "../DateFilter"
import ChartAlertsContainer from "./ChartAlertsContainer"
import TimelineSideAlerts from "../TimelineSideAlerts";

class TimelineAlertsContainer extends React.Component {

    constructor(props) {
        super(props);
        let now = new Date();
        this.state = {
            month: now.getMonth() + 1,
            year: now.getYear() + 1900
        };
    };

    onChangeDateHandler = (newMonth, newYear) => {

        this.setState({
            month: newMonth,
            year: newYear
        })
    };

    render() {
        return (
            <div className="row timeline">
                <DateFilter onChangeDateHandler={this.onChangeDateHandler} />
                <div className="col s12 left" style={{position: 'relative', marginBottom: '20px'}}>
                    <ChartAlertsContainer token={this.props.token} month={this.state.month} year={this.state.year} company={this.props.company} translate={this.props.translate} />
                    <TimelineSideAlerts token={this.props.token} month={this.state.month} year={this.state.year} company={this.props.company} translate={this.props.translate} />
                </div>
            </div>
        );
    }
}

export default TimelineAlertsContainer
