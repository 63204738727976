import React from "react"
import axios from "axios";

export function sendData(lead) {

    return axios.post('/api/v2/leads/', lead)
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(error);
        });
}

class FormLead extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isSending: false,
            empresa: "",
            nome: "",
            cargo: "",
            email: "",
            telefone: "",
            escolha: "",
            hasEmptyFields: false
        };

    };

    handleInputChange = (event) => {
        const target = event.target;
        this.setState({
            [target.name]: target.value
        });
    };

    handleSendButton = () => {
        if (this.state.empresa === "" ||
            this.state.nome === "" ||
            this.state.cargo === "" ||
            this.state.email === "" ||
            this.state.escolha === "" ||
            this.state.telefone === "") {

            this.setState({
                hasEmptyFields: true
            })
        } else {
            this.setState({
                isSending: true,
                hasEmptyFields: false
            });

            sendData({
                empresa: this.state.empresa,
                nome: this.state.nome,
                cargo: this.state.cargo,
                email: this.state.email,
                escolha: this.state.escolha,
                telefone: this.state.telefone
            })
                .then(response => {

                    this.setState({
                        isSending: false,
                        empresa: "",
                        nome: "",
                        cargo: "",
                        email: "",
                        telefone: "",
                        escolha: "",
                        hasEmptyFields: false
                    });

                    swal({
                        title: 'Contato enviado',
                        text: "Obrigado, entraremos em contato.",
                        type: 'success'
                    });
                })
                .catch(error => {
                    this.setState({
                        isSending: false
                    });
                    console.log(error);
                })

        }
    };
    

    render() {
        return (
            <div className="btn-indicacao-container row">
                <div className="input-field col s12">
                    <label>Empresa</label>
                    <input className="input-indicacao" onChange={this.handleInputChange} name="empresa"
                            type="text" value={this.state.empresa}/>
                </div>
                <div className="input-field col s12">
                    <label>Nome</label>
                    <input className="input-indicacao" onChange={this.handleInputChange} name="nome"
                            type="text" value={this.state.nome}/>
                </div>
                <div className="input-field col s12">
                    <label>Cargo</label>
                    <input className="input-indicacao" onChange={this.handleInputChange} name="cargo"
                            type="text" value={this.state.cargo}/>
                </div>
                <div className="input-field col s12">
                    <label>E-mail</label>
                    <input className="input-indicacao" onChange={this.handleInputChange} name="email"
                            type="text" value={this.state.email}/>
                </div>
                <div className="input-field col s12">
                    <label>Telefone</label>
                    <input className="input-indicacao" onChange={this.handleInputChange} name="telefone"
                            type="text" value={this.state.telefone}/>
                </div>
                <div className="input-field col s12">
                    <label>Qual é o seu principal problema?</label>
                    <select className=" input-indicacao" onChange={this.handleInputChange}
                            value={this.state.escolha} name="escolha">
                        <option value=""></option>
                        <option value="Desperdício energético na refrigeração">Desperdício energético na refrigeração</option>
                        <option value="Perdas de produtos">Perdas de produtos</option>
                        <option value="Falta de informações">Falta de informações</option>
                        <option value="Processo manual não confiável">Processo manual não confiável</option>
                        <option value="Retrabalho no monitoramento">Retrabalho no monitoramento</option>
                        <option value="Todos acima">Todos acima</option>
                    </select>
                </div>
                {this.state.hasEmptyFields ?
                    (
                        <div className="error-empty-fields">Existem campos não preenchidos</div>
                    ) : null
                }
                <button className="btn-send-indicacao btn" disabled={this.state.isSending}
                        onClick={this.handleSendButton}>Enviar
                </button>
            </div>
        )
    }
}

export default FormLead;