import React from "react"

class Botao extends React.Component {
    constructor(props){
        super(props)
    }

    render() {
        if(this.props.text > 0){
            var text = this.props.text
        }else{
            var text = 'OK'
        }

        if(this.props.id > 0 && this.props.id != true){
            var id = this.props.id
        }else{
            var id = 'botao'
        }

        if(this.props.type > 0){
            var type = this.props.type
        }else{
            var type = 'text'
        }
        
        if(this.props.classes.length > 0) {
            var classes = this.props.classes
        }else{
            var classes = 'btn left'
        }

        return(
            <a className={classes} ></a>
        );
    }
}

export default Botao  