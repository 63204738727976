import React from "react";
import moment from "moment"
import LoadingOverlay from "react-loading-overlay";
import PaletteColors from "../shared/PaletteColors";
import Dygraph from 'dygraphs';

class RelatorioCadeiaChartDygraph extends React.Component {

    constructor(props) {
        super(props);
        this.state = {height: props.height, width: props.width};
    }

    componentWillMount() {
        this.setState({height: window.innerHeight, width: window.innerWidth});
    }

    generateDygraphData(key, dataKey, labelsKey) {

        let dygraphLabels = ['Data'];
        let allDygraphDates = {};
        let dygraphData = [];

        this.props.etapas.forEach((etapa, index) => {

            let selectedCompany = this.props.companies.find(company => {
                return company.id == etapa.selected_company_id;
            });
            let selectedSensor = selectedCompany.sensors.find(sensor => {
                return sensor.id == etapa.selected_sensor_id;
            });

            dygraphLabels.push(selectedSensor.label);
            if (etapa[key]) {
                etapa[key].forEach(data => {
                    data[0] = new Date(data[0]);
                    allDygraphDates[data[0]] = []
                });
            }
        });

        this.props.etapas.forEach(etapa => {

            if (etapa[key]) {
                var allDygraphDatesCount = 0;

                etapa[key].forEach(data => {
                    allDygraphDates[data[0]].push(data[1])
                    allDygraphDatesCount = allDygraphDates[data[0]].length;
                });

                for (var date in allDygraphDates) {
                    if (allDygraphDates[date].length < allDygraphDatesCount) {
                        allDygraphDates[date].push(null)
                    }
                }
            }

        });

        for (var date in allDygraphDates) {
            var dataToPush = [new Date(date)];
            dygraphData.push(dataToPush.concat(allDygraphDates[date]));
        }

        dygraphData = dygraphData.sort((a, b) => {
            return a[0].getTime() - b[0].getTime()
        });

        this.setState({ [dataKey]: dygraphData, [labelsKey]: dygraphLabels })
    }

    renderDygraphChart(dygraphData, dygraphLabels, id, idLabels) {

        if (dygraphData && dygraphData.length && dygraphLabels.length > 1) {

            var showRangeSelector = false;
            if (this.state.width < 1024) {
                showRangeSelector = true
            }

            new Dygraph(
                id,
                dygraphData,
                {
                    colors: PaletteColors.getColors(),
                    labelsDiv: document.getElementById(idLabels),
                    labelsSeparateLines: true,
                    legend: 'always',
                    labels: dygraphLabels,
                    hideOverlayOnMouseOut: false,
                    connectSeparatedPoints: true,
                    legendFormatter: function legendFormatter(data) {

                        var counter = 0;
                        var html = '';
                        if (data.x == null) {
                            data.series.forEach(function (series) {
                                if (!series.isVisible || counter >= (data.series.length - 2))
                                    return;

                                var labeledData = series.labelHTML;
                                counter++;
                                html += ' ' + series.dashHTML + ' ' + labeledData + ' ';
                            });
                            return html;
                        }

                        data.series.forEach(function (series) {
                            if (!series.isVisible) {
                                return;
                            }

                            if (series.yHTML) {
                                var labeledData = series.labelHTML + ': <b>' + series.yHTML + '</b>';
                                if (series.isHighlighted) {
                                    labeledData = '<b>' + labeledData + '</b>';
                                }
                                counter++;
                                html += ' ' + series.dashHTML + ' ' + labeledData + ' ';
                            }

                        });

                        html += '<div class="data">' + this.getLabels()[0] + ': <b>' + new Intl.DateTimeFormat('pt-BR', {
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: 'numeric',
                            second: 'numeric',
                            hour12: false,
                            timeZone: 'America/Sao_Paulo'
                        }).format((new Date(data.xHTML))) + '</b></div>';
                        return html;
                    },
                    showRangeSelector: showRangeSelector,
                    rangeSelectorPlotStrokeColor: 'rgba(64, 189, 167, 1)',
                    rangeSelectorPlotFillColor: 'rgba(64, 189, 167, 0.5)',
                    rangeSelectorPlotFillGradientColor: 'rgba(64, 189, 167, 0.5)',
                    rangeSelectorForegroundStrokeColor: 'rgba(100, 100, 100, 0.7)'
                }
            )

        }
    }

    generateChartJSData(key, dygraphData, dygraphLabels) {
        moment.locale("pt-BR")

        let chartData = {
            labels: [],
            datasets: [],
            showReportButton: false
        };

        if(dygraphData && dygraphLabels) {

            // Adiciona todas as labels
            dygraphData.forEach((data) => {
                let dateX = new Date(data[0]);
                let valueX = moment(dateX.toString()).format("YYYY/MM/DD hh:mm")
                chartData.labels.push(valueX)
            });

            this.props.etapas.forEach((etapa, index) => {
                if (etapa[key]) {

                    chartData.showReportButton = true;

                    let valuesY = [];

                    let selectedCompany = this.props.companies.find(company => {
                        return company.id == etapa.selected_company_id;
                    });
                    let selectedSensor = selectedCompany.sensors.find(sensor => {
                        return sensor.id == etapa.selected_sensor_id;
                    });

                    dygraphData.forEach((data) => {
                        valuesY.push(data[index + 1])
                    });

                    let randomColor = PaletteColors.getColor(index);
                    chartData.datasets.push(
                        {
                            label: selectedSensor.label,
                            data: valuesY,
                            backgroundColor: "transparent",
                            borderColor: randomColor,
                            pointHoverBackgroundColor: randomColor,
                            spanGaps: true
                        }
                    );

                }
            });
        }

        return chartData;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.isLoading != this.props.isLoading && !this.props.isLoading) {
            this.generateDygraphData('chart_data', 'dygraphData', 'dygraphLabels');
            this.generateDygraphData('chart_data_humidity', 'dygraphDataHumidity', 'dygraphLabelsHumidity');
        }
        if(this.state.dygraphData && JSON.stringify(prevState.dygraphData) != JSON.stringify(this.state.dygraphData)) {
            this.renderDygraphChart(this.state.dygraphData, this.state.dygraphLabels, 'temperature-dygraph-chart', 'temperature-chart-labels');
        }
        if(this.state.dygraphDataHumidity && JSON.stringify(prevState.dygraphDataHumidity) != JSON.stringify(this.state.dygraphDataHumidity)) {
            this.renderDygraphChart(this.state.dygraphDataHumidity, this.state.dygraphLabelsHumidity, 'humidity-dygraph-chart', 'humidity-chart-labels');
        }
    }

    render() {

        let chartJSData = this.generateChartJSData('chart_data', this.state.dygraphData, this.state.dygraphLabels);
        let chartJSDataHumidity = this.generateChartJSData('chart_data_humidity', this.state.dygraphDataHumidity, this.state.dygraphLabelsHumidity);

        return (
            <div>
                <div className="relatorio-cadeia-chart-wrapper">

                    <div className="loading-wrapper" style={{display: this.props.isLoading ? 'block' : 'none'}}>
                        <LoadingOverlay
                            active={this.props.isLoading}
                            spinner
                            color="#1ABB9C"
                            text='Carregando dados para o gráfico...'/>
                    </div>

                    <h2 className="text-center">{this.props.translate.temperature}</h2>
                    <div className={"dygraph-chart-wrapper"}
                         style={{padding: chartJSData.showReportButton ? '10px' : '0px'}}>
                        <div id='temperature-chart-labels' style={{width: '100%'}}></div>
                        <div id='temperature-dygraph-chart' style={{width: '100%'}}></div>
                    </div>
                    <h2 className="text-center">{this.props.translate.humidity}</h2>
                    <div className={"dygraph-chart-wrapper"}
                         style={{padding: chartJSData.showReportButton ? '10px' : '0px'}}>
                        <div id='humidity-chart-labels' style={{width: '100%'}}></div>
                        <div id='humidity-dygraph-chart' style={{width: '100%'}}></div>
                    </div>
                </div>

                <form id="form-report-rastreabilidade" action="/pdf/report-rastreabilidade" target="_blank" method="POST">

                    <input name="etapas" type="hidden" value={JSON.stringify(this.props.etapas)}/>
                    <input name="companies" type="hidden" value={JSON.stringify(this.props.companies)}/>
                    <input name="chartjs" type="hidden" value={JSON.stringify(chartJSData)}/>
                    <input name="chartjsHumidity" type="hidden" value={JSON.stringify(chartJSDataHumidity)}/>

                    {!this.state.currentUserIsOperator ? (
                        <button className={"custom-btn custom-btn-success full-width modal-trigger"}
                            style={{display: chartJSData.showReportButton ? 'block' : 'none'}} 
                            data-target="modal-esignature-optional"
                            data-operation-code="5"
                            data-form-to-submit="form-report-rastreabilidade"
                            type="button">{this.props.translate.download_pdf_report}
                        </button>
                    ) : null}
                </form>
            </div>
        );
    }
}

export default RelatorioCadeiaChartDygraph
