import React from "react"
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import {Bar} from "react-chartjs-2";

class PalantirAccessByResource extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            collapsibleOpen: false,
            data: [],
            chart_data: []
        };
    };

    requestData = () => {

        this.setState({
            isLoading: true,
            data: [],
            chart_data: []
        });

        axios.get('/api/v2/access_by_resource')
            .then(response => {
                this.setState({
                    isLoading: false,
                    data: response.data
                });
                console.log(response.data);
            })
            .catch(error => {
                console.log(error);
                this.setState({isLoading: false});
            });

    }

    toggleCollapsible = () => {
        let currentStatus = this.state.collapsibleOpen;
        this.setState({collapsibleOpen: !currentStatus})
    }

    componentDidMount() {
        this.requestData();
    }

    render() {

        let borderColors = ['#004c6d', '#255e7e', '#3d708f', '#5383a1', '#6996b3', '#7faac6', '#94bed9', '#abd2ec', '#c1e7ff'];
        let backgroundColors = ['#004c6d', '#255e7e', '#3d708f', '#5383a1', '#6996b3', '#7faac6', '#94bed9', '#abd2ec', '#c1e7ff'];
        let count = 0;
        let maxItemsForChart = 9;
        let chartData = {
            datasets: []
        };

        if (this.state.data) {
            this.state.data.slice(0, maxItemsForChart).forEach(item => {
                console.log(item)
                chartData.datasets.push({
                    label: item.recurso,
                    backgroundColor: backgroundColors[count],
                    borderColor: borderColors[count],
                    hoverBackgroundColor: borderColors[count],
                    borderWidth: 3,
                    data: [item.acessos]
                });
                count++;
            });
        }

        let chartOptions = {
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        return tooltipItem.yLabel + " acessos";
                    }
                }
            },
            legend: {
                position: 'top',
            },
            layout: {
                padding: {
                    top: 10,
                    right: 20,
                    bottom: 0,
                    left: 20
                }
            },
            scales: {
                yAxes: [{
                    display: true,
                    ticks: {
                        maxTicksLimit: 6,
                        beginAtZero: true
                    }
                }]
            }
        };

        let chart = null;
        let list = null;
        if (this.state.data && this.state.data.length) {
            chart = (<Bar data={chartData} options={chartOptions}/>)

            let items = this.state.data.map(item => {
                return (
                    <div key={item.original_resource} style={{padding: '16px'}}>
                        {item.recurso} - {item.acessos}
                    </div>
                )
            })

            list = (
                <ul className="collapsible" onClick={this.toggleCollapsible}>
                    <li>
                        <div className="collapsible-header">Acessos por funcionalidade</div>
                        <div style={{display: this.state.collapsibleOpen ? 'block' : 'none'}}>{items}</div>
                    </li>
                </ul>
            )
        }

        return (
            <div className="s12" style={{marginTop: '20px'}}>
                <h2>Acessos por funcionalidade</h2>
                <div className="loading-wrapper" style={{display: this.state.isLoading ? 'block' : 'none'}}>
                    <LoadingOverlay
                        active={this.state.isLoading}
                        spinner
                        color="#40BDA7"
                        text={"Carregando dados..."}/>
                </div>
                {chart}
                {list}
            </div>
        )
    }
}

export default PalantirAccessByResource;