import React from "react"
import DateFilter from "../DateFilter"
import DesempenhoFixosContainer from "./fixos/DesempenhoFixosContainer";
import DesempenhoTransporteContainer from "./transporte/DesempenhoTransporteContainer";
import BtnRelatorioEvolucaoMensal from "./BtnRelatorioEvolucaoMensal";

class DesempenhoPage extends React.Component {

    constructor(props) {
        super(props);
        let now = new Date();
        this.state = {
            month: now.getMonth() + 1,
            year: now.getYear() + 1900
        };BtnRelatorioEvolucaoMensal
    };

    onChangeDateHandler = (newMonth, newYear) => {
        this.setState({
            month: newMonth,
            year: newYear
        })
    };

    render() {

        let fixosContainer = null;
        if(this.props.sensor_numbers.fixos > 0) {
            fixosContainer = (<DesempenhoFixosContainer token={this.props.token} month={this.state.month} year={this.state.year} company={this.props.company} />)
        }

        let transporteContainer = null;
        if(this.props.sensor_numbers.transporte > 0) {
            transporteContainer = (<DesempenhoTransporteContainer token={this.props.token} month={this.state.month} year={this.state.year} company={this.props.company} />)
        }

        return (
            <div className="desempenho-container-page">
                <DateFilter onChangeDateHandler={this.onChangeDateHandler} />
                <div className="row left-align" style={{position: 'relative', marginBottom: '20px'}}>
                    {fixosContainer}
                    {transporteContainer}
                </div>
                <BtnRelatorioEvolucaoMensal month={this.state.month} year={this.state.year} company={this.props.company} hasFixos={this.props.sensor_numbers.fixos > 0} />
            </div>
        );
    }
}

export default DesempenhoPage
