import React from "react"
import {Bar, Line} from "react-chartjs-2"
import LoadingOverlay from "react-loading-overlay";

class ChartWorstSensorsAlerts extends React.Component {

    constructor(props) {
        super(props);
        let now = new Date();
        this.state = {
            mes: now.getMonth() + 1,
            ano: now.getYear() + 1900
        };
    };

    render() {

        let backgroundColors = ["#F4433670", "#4bc0c07a", "#36a2eb73"];
        let borderColors = ["#F44336", "#4bc0c0", "#36a2eb"];
        let count = 0;
        let chartData = {
            datasets: []
        };

        this.props.data.forEach(sensor => {
            chartData.datasets.push({
                label: sensor.label,
                backgroundColor: backgroundColors[count],
                borderColor: borderColors[count],
                hoverBackgroundColor: borderColors[count],
                borderWidth: 3,
                data: [sensor.numero_alertas]
            });
            count++;
        });

        let chartOptions = {
            tooltips: {
                callbacks: {
                    title: function (tooltipItem, data) {
                        return data.datasets[tooltipItem[0].datasetIndex].label;
                    },
                    label: function (tooltipItem, data) {
                        return " " + tooltipItem.yLabel + " envios";
                    }
                }
            },
            legend: {
                position: 'top',
            },
            layout: {
                padding: {
                    top: 10,
                    right: 20,
                    bottom: 0,
                    left: 20
                }
            },
            scales: {
                yAxes: [{
                    display: true,
                    ticks: {
                        beginAtZero: true
                    }
                }]
            }
        };

        let chart = null;
        if(this.props.data.length) {
            chart = (<Bar data={chartData} options={chartOptions} />)
        }

        if (this.props.noData) {
            chart = (<div className="no-data-timeline"><i className="fa fa-check fa-margin-top" style={{fontSize: 3 + 'em', color: "#40BDA7"}}></i><br/><span style={{fontSize: 2 + 'em', color: "#40BDA7"}}>Parabéns!<br/>Você não possui envio de alertas neste mês.</span></div>)
        }

        return (
            <div className="row">
                <h4 className="page-subtitle timeline-page-subtitle">Sensores com maior recorrência de envio</h4>
                <div className="react-chart-area">
                    <div className="loading-wrapper" style={{display: this.props.isLoading ? 'block' : 'none'}}>
                        <LoadingOverlay
                            active={this.props.isLoading}
                            spinner
                            color="#40BDA7"
                            text='Carregando dados de sensores...'/>
                    </div>
                    {chart}
                </div>
            </div>
        );
    }
}

export default ChartWorstSensorsAlerts
