
const colors = ['#40BDA7', '#FF5722', '#FFD600', '#795548', '#50514F', '#9E9E9E', '#2E7D32', '#4A148C', '#880E4F', '#FFD180'];

let getColor = (index) => {
    return colors[index];
};

let getColors = () => {
    return colors;
};

export default {getColor, getColors}