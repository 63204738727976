import React from "react";
import PaletteColors from "../shared/PaletteColors";
import LoadingOverlay from "react-loading-overlay";
import * as Requests from "../relatorio_cadeia/RelatorioCadeiaRequests";

class AnaliseTemporalTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            tableData: [],
            isVisible: false
        };
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.filterParams.dateInicio && this.props.filterParams.dateFim) {

            if (!this.state.isVisible) {
                this.setState({isVisible: true})
            }

            if (this.props.filterParams.dateInicio != prevProps.filterParams.dateInicio ||
                this.props.filterParams.dateFim != prevProps.filterParams.dateFim ||
                this.props.filterParams.selectedSensorId != prevProps.filterParams.selectedSensorId ||
                this.props.selectedWeeks != prevProps.selectedWeeks) {

                this.requestDataForTable()

            }

        }
    }

    requestDataForTable = () => {

        if (this.props.filterParams.dateInicio && this.props.filterParams.dateFim) {

            this.setState({isLoading: true});
            Requests.fetchTableDataWeekly(
                this.props.filterParams.selectedSensorId,
                this.props.filterParams.dateInicio,
                this.props.filterParams.dateFim,
                this.props.selectedWeeks,
                this.props.filterParams.token)
                .then(result => {
                    this.setState({
                        tableData: result.data,
                        isLoading: false
                    })
                })
                .catch(err => {
                    this.setState({
                        isLoading: false
                    })
                });
        }

    };

    render() {

        let tableArea = null;
        if (this.state.tableData.length) {

            let rowsTable = [];
            let hashedData = {};

            this.state.tableData.map((weekData, index_table_data) => {

                weekData.data.map((rowData, index) => {

                    if(!hashedData[index]) {
                        hashedData[index] = []
                    }

                    let rowLines = rowData.dados.map((lineData, index) => {
                        return <td key={"td" + index + "_" + index_table_data} className={lineData.class}>{lineData.value}</td>
                    });

                    let markup = (<tr key={"tr" + index + "_" + index_table_data}>
                        <td style={{
                            borderLeftColor: PaletteColors.getColor(index_table_data),
                            borderLeftWidth: '10px',
                            borderLeftStyle: 'solid'
                        }}>{rowData.dia}</td>
                        {rowLines}
                    </tr>);

                    hashedData[index].push(markup);

                    return markup
                });

            });

            Object.keys(hashedData).forEach(function(key,index) {

                rowsTable.push(<tr key={"divider" + index}><td><br/></td></tr>);

                hashedData[key].forEach(rowMarkup => {
                    rowsTable.push(rowMarkup);
                });

            });

            tableArea = (<div className="table-area">
                <div className="col s12">
                    <table>
                        <thead>
                        <tr>
                            <th>Dia</th>
                            <th>0H</th>
                            <th>1H</th>
                            <th>2H</th>
                            <th>3H</th>
                            <th>4H</th>
                            <th>5H</th>
                            <th>6H</th>
                            <th>7H</th>
                            <th>8H</th>
                            <th>9H</th>
                            <th>10H</th>
                            <th>11H</th>
                            <th>12H</th>
                            <th>13H</th>
                            <th>14H</th>
                            <th>15H</th>
                            <th>16H</th>
                            <th>17H</th>
                            <th>18H</th>
                            <th>19H</th>
                            <th>20H</th>
                            <th>21H</th>
                            <th>22H</th>
                            <th>23H</th>
                        </tr>
                        </thead>
                        <tbody>

                        {rowsTable}

                        </tbody>
                    </table>
                </div>
            </div>);
        }

        return (

            <div>

                {this.state.isVisible ? (

                    <div className="analise-temporal-table-wrapper">

                        <div className="loading-wrapper" style={{display: this.state.isLoading ? 'block' : 'none'}}>
                            <LoadingOverlay
                                active={this.state.isLoading}
                                spinner
                                color="#1ABB9C"
                                text='Carregando dados para relatório...'/>
                        </div>

                        {!this.state.isLoading ? tableArea : null}
                    </div>

                ) : null
                }

            </div>
        );
    }

}

export default AnaliseTemporalTable