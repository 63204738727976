import React from 'react';

class MapCompanyMarker extends React.Component {

    render() {

        let hiddenContent = (
            <div className={'company-marker-hidden-content company-item'}>
                <b>{this.props.company.name}</b>
                <div className="company-details">
                    <a target="_blank" href={"/change_company/" + this.props.company.id}
                       className={"btn btn-small btn-primary"}>Detalhes</a>
                </div>
            </div>);

        return (
            <div className='company-marker'>
                <img src='/images/icon_factory.png' width={32} height={32}/>
                {hiddenContent}
            </div>
        );
    }
}

export default MapCompanyMarker
