import React from "react";
import {Line} from "react-chartjs-2";
import moment from "moment"
import LoadingOverlay from "react-loading-overlay";

class RelatorioCadeiaChart extends React.Component {

    getColor = (index) => {

        let colors = ['#40BDA7', '#FF92A8', '#F6BD60', '#36A1EB', '#F5CAC3',
            '#AF8033', '#2EE8E1', '#EF5B5B', '#23001E', '#A4A9AD', '#C3EF1E', '#0900AD'];

        return colors[index];
    };

    render() {

        moment.locale("pt-BR")

        let chartData = {
            labels: [],
            datasets: []
        };

        this.props.etapas.forEach(etapa => {
            if (etapa.chart_data) {
                for (const [key, value] of Object.entries(etapa.chart_data)) {
                    chartData.labels.push(key);
                }
            }
        });

        chartData.labels = chartData.labels.sort((a, b) => {
            return a.localeCompare(b)
        });

        let showReportButton = false;

        this.props.etapas.forEach((etapa, index) => {

            if (etapa.chart_data) {

                showReportButton = true;

                let selectedCompany = this.props.companies.find(company => {
                    return company.id == etapa.selected_company_id;
                });
                let selectedSensor = selectedCompany.sensors.find(sensor => {
                    return sensor.id == etapa.selected_sensor_id;
                });

                let dados = [];

                chartData.labels.forEach(key => {
                    if (etapa.chart_data[key]) {
                        dados.push(parseFloat(etapa.chart_data[key]));
                    } else {
                        dados.push(null);
                    }
                });

                let randomColor = this.getColor(index);

                chartData.datasets.push(
                    {
                        label: selectedSensor.label,
                        data: dados,
                        backgroundColor: "transparent",
                        borderColor: randomColor,
                        pointHoverBackgroundColor: randomColor,
                        spanGaps: true
                    }
                )

            }

        });

        let chartOptions = {
            maintainAspectRatio: true,
            scales: {
                xAxes: [{
                    display: true,
                    scaleLabel: {
                        display: true
                    },
                    ticks: {
                        display: true
                    }
                }],
                yAxes: [{
                    display: true,
                    scaleLabel: {
                        display: false,
                        labelString: 'HH:mm'
                    }
                }]
            },
            layout: {
                padding: {
                    top: 20,
                    right: 20,
                    bottom: 0,
                    left: 20
                }
            }
        };

        let chart = null;
        if (this.props.etapas && this.props.etapas[0] && this.props.etapas[0].chart_data && this.props.isLoading == false) {
            chart = (<Line
                height={100}
                data={chartData}
                options={chartOptions}
            />)
        }

        return (
            <div>
                <div className="relatorio-cadeia-chart-wrapper">

                    <div className="loading-wrapper" style={{display: this.props.isLoading ? 'block' : 'none'}}>
                        <LoadingOverlay
                            active={this.props.isLoading}
                            spinner
                            color="#40BDA7"
                            text='Carregando dados para o gráfico...'/>
                    </div>

                    {chart}
                </div>

                <form action="/pdf/report-rastreabilidade" target="_blank" method="POST">

                    <input name="etapas" type="hidden" value={JSON.stringify(this.props.etapas)} />
                    <input name="companies" type="hidden" value={JSON.stringify(this.props.companies)} />
                    <input name="chartjs" type="hidden" value={JSON.stringify(chartData)} />

                    <button style={{display: showReportButton ? 'block' : 'none'}} className={"custom-btn custom-btn-success full-width"}>{this.props.translate.download_pdf_report}</button>
                </form>
            </div>
        );
    }
}

export default RelatorioCadeiaChart
