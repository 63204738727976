import axios from "axios";

const CancelTokenEfficiencyData = axios.CancelToken;
var cancelEfficiencyData;
const fetchEfficiencyData = function (obj_id, data_inicio, data_final, token, measurement_type = 'humidity', group_type = 'min') {
    cancelEfficiencyData && cancelEfficiencyData()

    return axios.get('/api/v2/relatorio-cadeia/efficiency-bar/' + obj_id + '/' + data_inicio.getTime() + '/' + data_final.getTime() + '/' + measurement_type + '/' + group_type,
        {
            headers: {
                "Authorization": token
            },
            cancelToken: new CancelTokenEfficiencyData(
                function executor(c) {
                    cancelEfficiencyData = c;
                }
            )
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(error);
        });
};


const CancelTokenChartData = axios.CancelToken;
var cancelChartData;
const fetchChartData = function (obj_id, data_inicio, data_final, token, measurement_type = 'humidity', group_type = 'min') {
    cancelChartData && cancelChartData()

    return axios.get('/api/v2/relatorio-cadeia/chart-data/' + obj_id + '/' + data_inicio.getTime() + '/' + data_final.getTime() + '/' + measurement_type + '/' + group_type,
        {
            headers: {
                "Authorization": token
            },
            cancelToken: new CancelTokenChartData(
                function executor(c) {
                    cancelChartData = c;
                }
            )
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(error);
        });
};

const CancelTokenChartDataHourly = axios.CancelToken;
var cancelChartDataHourly;
const fetchChartDataHourly = function (obj_id, data_inicio, data_final, week_offset, token, measurement_type = 'humidity', group_type = 'min') {
    cancelChartDataHourly && cancelChartDataHourly()

    return axios.get('/api/v2/relatorio-cadeia/chart-data-hourly/' + obj_id + '/' + data_inicio.getTime() + '/' + data_final.getTime() + '/' + week_offset + '/' + measurement_type + '/' + group_type,
        {
            headers: {
                "Authorization": token
            },
            cancelToken: new CancelTokenChartDataHourly(
                function executor(c) {
                    cancelChartDataHourly = c;
                }
            )
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(error);
        });
};


const CancelTokenOverviewData = axios.CancelToken;
var cancelOverviewData;
const fetchOverviewData = function (obj_id, data_inicio, data_final, token, measurement_type = 'humidity', group_type = 'min') {
    cancelOverviewData && cancelOverviewData()

    return axios.get('/api/v2/relatorio-cadeia/overview-data/' + obj_id + '/' + data_inicio.getTime() + '/' + data_final.getTime() + '/' + measurement_type + '/' + group_type,
        {
            headers: {
                "Authorization": token
            },
            cancelToken: new CancelTokenOverviewData(
                function executor(c) {
                    cancelOverviewData = c;
                }
            )
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(error);
        });
};



const CancelTokenTableData = axios.CancelToken;
var canceTableData;
const fetchTableData = function (obj_id, data_inicio, data_final, token, measurement_type = 'humidity', group_type = 'min') {
    canceTableData && canceTableData()

    return axios.get('/api/v2/relatorio-cadeia/table-data/' + obj_id + '/' + data_inicio.getTime() + '/' + data_final.getTime() + '/' + measurement_type + '/' + group_type,
        {
            headers: {
                "Authorization": token
            },
            cancelToken: new CancelTokenTableData(
                function executor(c) {
                    canceTableData = c;
                }
            )
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(error);
        });
};


const CancelTokenTableDataWeekly = axios.CancelToken;
var cancelTableDataWeekly;
const fetchTableDataWeekly = function (obj_id, data_inicio, data_final, week_offset, token, measurement_type = 'humidity', group_type = 'min') {
    cancelTableDataWeekly && cancelTableDataWeekly()

    return axios.get('/api/v2/relatorio-cadeia/table-data-weekly/' + obj_id + '/' + data_inicio.getTime() + '/' + data_final.getTime() + '/' + week_offset + '/' + measurement_type + '/' + group_type,
        {
            headers: {
                "Authorization": token
            },
            cancelToken: new CancelTokenTableDataWeekly(
                function executor(c) {
                    cancelTableDataWeekly = c;
                }
            )
        })
        .then(response => {
            return response;
        })
        .catch(error => {
            return Promise.reject(error);
        });
};


export {fetchEfficiencyData, fetchChartData, fetchChartDataHourly, fetchOverviewData, fetchTableData, fetchTableDataWeekly}