import React from "react";
import * as Requests from './SelectCompanyRequests'

class SelectCompany extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            companies: []
        };
    };

    onChange = (event) => {
        const target = event.target;

        let selectedCompany = this.state.companies.find(company => {
            return company.id == target.value
        });

        this.props.onSelectedCompany(selectedCompany);
    };

    componentDidMount() {
        Requests.getUserCompanies(this.props.userId, this.props.token)
            .then(result => {
                this.setState({companies: result.data})
                if (result.data.length) {
                    this.props.onSelectedCompany(result.data[0]);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    render() {

        let optionsCompanies = this.state.companies.map(company => {
            return (<option key={company.id}
                            value={company.id}>{company.name}</option>)
        });

        return (
            <div className="custom-input-wrapper">
                <div className="custom-input-label">Empresa</div>
                <select className="custom-input"
                        name="selected_company_id"
                        onChange={this.onChange}>
                    {optionsCompanies}
                </select>
            </div>
        );
    }

}

export default SelectCompany
