import React from "react"
import axios from "axios";
import PalantirAccessByCompany from "./PalantirAccessByCompany";
import PalantirAccessByResource from "./PalantirAccessByResource";

class PalantirPage extends React.Component {

    constructor(props) {
        super(props);
    };

    render() {
        return (
            <div className="row">
                <PalantirAccessByCompany/>
                <PalantirAccessByResource/>
            </div>
        )
    }
}

export default PalantirPage;