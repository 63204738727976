import React from "react"
import LoadingOverlay from "react-loading-overlay";

class SmeMessages extends React.Component {

    render() {
        return(
            <h2>Hallo</h2>
        );
    }
}

export default SmeMessages