import React from "react"
import DateFilter from "../DateFilter"
import axios from "axios";
import Messages from '../sme_messages/SmeMessages';
import SmeMenu from '../sme_messages/SmeMenu';
import SmeList from '../sme_messages/SmeList';

const CancelToken = axios.CancelToken;
var cancel;

class SmeMessagesContainer extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        return(
            <SmeMenu
                token={this.props.token}
            />
        );
    }
}

export default SmeMessagesContainer
