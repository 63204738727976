import React from "react";
import {
    Charts,
    ChartContainer,
    ChartRow,
    EventChart,
    Resizable
} from "react-timeseries-charts";
import moment from "moment";
import * as Requests from "./DoorStatusRequests";

// Pond
import {TimeSeries, TimeRangeEvent, TimeRange} from "pondjs";

class DoorStatusContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sensorId: this.props.sensorId,
            isLoading: false,
            initialDate: this.props.initialDate,
            finalDate: this.props.finalDate,
            readings: [],
            series: [],
            highlighted: false,
            highlightedData: null,
            opening_count: 0,
            open_hours: 0,
            closed_hours: 0,
            open_percentage: 0,
            closed_percentage: 0
        };
    };

    componentDidMount() {
        window[this.props.ref_name] = this;
        this.requestDataForChart()
    }

    componentWillUnmount() {
        delete window[this.props.ref_name]
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevState.initialDate != this.state.initialDate) || (prevState.finalDate != this.state.finalDate)) {
            this.requestDataForChart();
        }
    }

    changeDates = (initialDate, finalDate) => {
        this.setState({
            initialDate: initialDate,
            finalDate: finalDate,
        })
    };

    readingsStyleFunc = (event, state) => {
        const color = event.get("type") === "closed" ? "#40bda7" : "#7f7f7f";
        switch (state) {
            case "normal":
                return {
                    fill: color
                };
            case "hover":
                return {
                    fill: color,
                    opacity: 0.4
                };
            case "selected":
                return {
                    fill: color,
                    opacity: 0.2
                };
            default:
        }
    };

    requestDataForChart = () => {

        if (this.state.initialDate && this.state.finalDate) {
            this.setState({isLoading: true})
            Requests.fetchDoorStatusData(
                this.state.sensorId,
                this.state.initialDate,
                this.state.finalDate,
                this.props.token)
                .then(result => {

                    this.setState({
                        opening_count: result.data.opening_count,
                        open_hours: result.data.open_hours,
                        open_percentage: result.data.percentage.open,
                        closed_percentage: result.data.percentage.closed,
                        closed_hours: result.data.closed_hours,
                        readings: result.data.door_statuses,
                        isLoading: false
                    })

                })
                .catch(err => {
                    this.setState({
                        isLoading: false
                    })
                });
        }

    };

    getHighlightedDataFromSelection = (selection) => {
        return {
            type: selection.get("type"),
            duration: selection.timerange().duration(),
            beginDate: selection.timerange().begin(),
            endDate: selection.timerange().end(),
            doorChangeCount: selection.get("doorChangeCount")
        }
    };

    onSelectionChange = (selection) => {
        this.setState({
            highlighted: true,
            highlightedData: this.getHighlightedDataFromSelection(selection)
        });
    };

    onMouseOver = (selection) => {
        this.setState({
            highlighted: true,
            highlightedData: this.getHighlightedDataFromSelection(selection)
        });
    };

    onMouseLeave = (selection) => {
        this.setState({
            highlighted: false
        });
    };

    render() {

        const events = this.state.readings.map(
            ({startTime, endTime, ...data}) =>
                new TimeRangeEvent(new TimeRange(new Date(startTime), new Date(endTime)), data)
        );

        const series = new TimeSeries({name: "outages", events});

        return (
            <div className="col s12 left">

                {this.state.readings.length ? (
                        <div>
                            <h2>
                                Gráfico do sensor de porta
                                <span className="material-icons md-24" style={{verticalAlign: 'middle'}}>
                                    meeting_room
                                </span>
                            </h2>
                            <div className="show-on-medium-and-up">
                                <Resizable>
                                    <ChartContainer
                                        timeRange={series.timerange()}
                                        enablePanZoom={true}
                                        format={(date) => {
                                            return moment(date).format('DD/MM HH:mm')
                                        }}
                                        style={{width: '100%', float: 'left'}}>
                                        <ChartRow height="30">
                                            <Charts>
                                                <EventChart
                                                    series={series}
                                                    size={45}
                                                    onSelectionChange={this.onSelectionChange}
                                                    onMouseOver={this.onMouseOver}
                                                    onMouseLeave={this.onMouseLeave}
                                                    style={this.readingsStyleFunc}
                                                />
                                            </Charts>
                                        </ChartRow>
                                    </ChartContainer>
                                </Resizable>
                            </div>
                            <div className={"percentage-container"}>
                                <div className={"row"}>
                                    <div className={"col s6"}>
                                        <div className={"percentage-data open"}>
                                            <div className={"percentage-number"}>{this.state.open_percentage}%</div>
                                            <div className={"percentage-description"}>Aberto</div>
                                        </div>
                                    </div>
                                    <div className={"col s6"}>
                                        <div className={"percentage-data closed"}>
                                            <div className={"percentage-number"}>{this.state.closed_percentage}%</div>
                                            <div className={"percentage-description"}>Fechado</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {this.state.highlighted ? (
                                <div className={"door-status-item-information"}>
                                    <div className={"row"}>
                                        <div className={"col s4"}>
                                            <div className={"door-status-item-container"}>
                                                <div className={"door-status-period-info"}>
                                                    <span>Início:</span> {moment(this.state.highlightedData.beginDate).format('DD/MM/YYYY HH:mm')}
                                                </div>
                                                <div className={"door-status-period-info"}>
                                                    <span>Fim:</span> {moment(this.state.highlightedData.endDate).format('DD/MM/YYYY HH:mm')}
                                                </div>
                                                <div className={"door-status-period-info"}>
                                                    <span>Duração:</span> {this.state.highlightedData.duration / 1000 / 60} minutos
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"col s4"}>
                                            <div className={"door-status-item-container"}>
                                                <div className={"door-status-title-info"}>Status</div>
                                                <div
                                                    className={"door-status-main-info " + this.state.highlightedData.type}>{this.state.highlightedData.type === "open" ? "Aberta" : "Fechada"}</div>
                                            </div>
                                        </div>
                                        <div className={"col s4"}>
                                            <div className={"door-status-item-container"}>
                                                <div className={"door-status-title-info"}>Quantidade de aberturas</div>
                                                <div
                                                    className={"door-status-main-info"}>{this.state.highlightedData.doorChangeCount}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className={"door-status-item-information"}>
                                    <div className={"row"}>
                                        <div className={"col s4"}>
                                            <div className={"door-status-item-container normal-state"}>
                                                <div className={"door-status-title-info"}>Quantidade de Aberturas</div>
                                                <div
                                                    className={"door-status-main-info closed"}>{this.state.opening_count}</div>
                                                <div className={"door-status-main-footer"}>No período filtrado</div>
                                            </div>
                                        </div>
                                        <div className={"col s4"}>
                                            <div className={"door-status-item-container normal-state"}>
                                                <div className={"door-status-title-info"}>Horas Aberto</div>
                                                <div className={"door-status-main-info"}>{this.state.open_hours}</div>
                                                <div className={"door-status-main-footer"}>No período filtrado</div>
                                            </div>
                                        </div>
                                        <div className={"col s4"}>
                                            <div className={"door-status-item-container normal-state"}>
                                                <div className={"door-status-title-info"}>Horas Fechado</div>
                                                <div
                                                    className={"door-status-main-info closed"}>{this.state.closed_hours}</div>
                                                <div className={"door-status-main-footer"}>No período filtrado</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>
                    ) :
                    this.state.isLoading ? (<div>Carregando dados de leituras de porta...</div>) : (
                        <div>Nenhuma leitura de porta no período selecionado</div>
                    )
                }

            </div>
        );
    }
}

export default DoorStatusContainer
